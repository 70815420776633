import { render, staticRenderFns } from "./BasePanel.vue?vue&type=template&id=21de0e9f&"
import script from "./BasePanel.vue?vue&type=script&lang=js&"
export * from "./BasePanel.vue?vue&type=script&lang=js&"
import style0 from "./BasePanel.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports