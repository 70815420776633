<template>
    <form @submit.prevent=""
          @change.prevent="">
        <div class="form-group">
            <label for="name">
                {{ $t("Name") }}
            </label>
            <input id="name"
                   name="name"
                   ref="name"
                   type="text"
                   v-model="$parent.frameGroup.name">
        </div>
    </form>
</template>

<script>
    export default {
        name: "WindowFrameGroupEditGeneral",
    };
</script>
