<template>
    <base-panel panel-class="modal notification-modal"
                ref="panel">
        <div class="header">
            {{ ucf$t("notifications.new") }}
            <div class="close"
                 @click="close">
                <img alt="close"
                     src="/images/icons/close-icon.svg">
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <label for="customer">
                    {{ ucf$t("notifications.company") }}
                </label>
                <multiselect :multiple="false"
                             :options="customers"
                             :placeholder="ucf$t('notifications.select_company')"
                             @close="updateProjects"
                             class="w-1/4 mr-4"
                             deselect-label=""
                             id="customer"
                             label="name"
                             ref="customerId"
                             select-label=""
                             selected-label=""
                             track-by="id"
                             v-model="notification.customer" />
            </div>

            <div class="form-group">
                <label for="project">
                    {{ ucf$t("notifications.project") }}
                    <span class="optional">
                        {{ ucf$t("global.optional") }}
                    </span>
                </label>
                <multiselect :multiple="false"
                             :options="projects"
                             :placeholder="ucf$t('notifications.select_project')"
                             class="w-1/4 mr-4"
                             deselect-label=""
                             id="project"
                             label="name"
                             ref="projectId"
                             select-label=""
                             selected-label=""
                             track-by="id"
                             v-model="notification.project" />
            </div>

            <div class="form-group">
                <label for="role">
                    {{ ucf$t("notifications.role") }}
                    <span class="optional">
                        {{ ucf$t("global.optional") }}
                    </span>
                </label>
                <multiselect :multiple="false"
                             :options="roles"
                             :placeholder="ucf$t('notifications.select_role')"
                             class="w-1/4 mr-4"
                             deselect-label=""
                             id="role"
                             label="name"
                             ref="roleId"
                             select-label=""
                             selected-label=""
                             track-by="id"
                             v-model="notification.role" />
            </div>
            <div class="form-group">
                <label for="text">
                    {{ ucf$t("notifications.text") }}
                </label>
                <textarea :placeholder="ucf$t('notifications.text_placeholder')"
                          id="text"
                          ref="text"
                          v-model="notification.text" />
            </div>

            <button class="primary"
                    @click="submit">
                {{ ucf$t("notifications.send") }}
            </button>
        </div>
    </base-panel>
</template>

<script>
    import moment from 'moment';
    import Multiselect from 'vue-multiselect';
    import BasePanel from '../components/BasePanel';

    export default {
        name: 'NotificationCreate',
        components: { BasePanel, Multiselect },
        data() {
            return {
                notification: {},
                roles: [],
                customers: [],
                projects: [],
            };
        },
        methods: {
            getRoles() {
                this.$http.get('/roles')
                    .then((response) => {
                        this.roles = response.data.data;
                    });
            },
            getCustomers() {
                this.$http.get('/customers')
                    .then((response) => {
                        this.customers = response.data.data;
                    });
            },
            updateProjects() {
                this.$http.get(`/customers/${this.notification.customer.id}/projects`)
                    .then((response) => {
                        this.projects = response.data.data;
                    });
            },
            submit() {
                const data = {
                    customerId: this.notification.customer !== null ? this.notification.customer.id : null,
                    projectId: this.notification.project !== null ? this.notification.project.id : null,
                    roleId: this.notification.role !== null ? this.notification.role.id : null,
                    text: this.notification.text,
                };

                this.$http.post('/notifications', data)
                    .then(() => {
                        this.$router.replace({
                            name: 'notifications.index',
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
            close() {
                this.$router.replace({
                    name: 'projects.show',
                    params: { projectId: this.project.id },
                    query: this.$route.query,
                });
            },
        },
        mounted() {
            this.$refs.panel.open();
            this.getCustomers();
            this.getRoles();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    };
</script>
