import FrameCreateWeights from "../../../views/FrameCreateWeights";
import FrameCreateWoods from "../../../views/FrameCreateWoods";
import FrameEditWeights from "../../../views/FrameEditWeights";
import FrameEditWoods from "../../../views/FrameEditWoods";
import FrameDeleteWeights from "../../../views/FrameDeleteWeights";
import FrameDeleteWoods from "../../../views/FrameDeleteWoods";
import FrameIndex from "../../../views/FrameIndex";
import auth from "../../middleware/_auth";
import role from "../../middleware/_role";
import { roles } from "../../../configuration/variables";
import ModelBoundView from "../../utils/ModelBoundView";
import GlassApplicationService from "../../../services/GlassApplicationService";
import FrameWeightService from "../../../services/FrameWeightService";
import FrameWoodService from "../../../services/FrameWoodService";

export default [
    {
        name: 'frames.index',
        path: '/frames/:category?',
        component: FrameIndex,
        children: [
            {
                name: 'frames.create.weights',
                path: 'create',
                component: FrameCreateWeights,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },

            {
                name: 'frames.create.woods',
                path: 'create',
                component: FrameCreateWoods,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },

            {
                name: 'frames.edit.weights',
                path: ':frameWeightId/edit',
                component: ModelBoundView(new FrameWeightService, FrameEditWeights),
                meta: {
                    middleware: [
                        auth,
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },

            {
                name: 'frames.edit.woods',
                path: ':frameWoodId/edit',
                component: ModelBoundView(new FrameWoodService, FrameEditWoods),
                meta: {
                    middleware: [
                        auth,
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },

            {
                name: 'frames.delete.weights',
                path: ':frameWeightId?/delete',
                component: FrameDeleteWeights,
                meta: {
                    middleware: [
                        auth,
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },

            {
                name: 'frames.delete.woods',
                path: ':frameWoodId?/delete',
                component: FrameDeleteWoods,
                meta: {
                    middleware: [
                        auth,
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },


        ],
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ to, next }) => {
                    if (!to.params.category) {
                        return next({ name: 'frames.index', params: { category: 'brands' } });
                    }

                    return next();
                },

            ],
            forbiddenRoute: { name: 'frames.index' },
        },
    },
]