m
<template>
    <transition name="fade">
        <div :key="$route.params.category"
             class="upper-rack-container">
            <div class="header">
                <h1>{{ ucfirst($t("racks.upper.upper_racks")) }}</h1>
            </div>
            <div class="tabs">
                <router-link :to="{ name: $route.name, params: { category: 'upper-rack-inner-plates' } }"
                             class="tab-item">
                    {{ ucfirst($t("racks.upper.inner_plates")) }}
                </router-link>
                <router-link :to="{ name: $route.name, params: { category: 'upper-rack-outer-plates' } }"
                             class="tab-item">
                    {{ ucfirst($t("racks.upper.outer_plates")) }}
                </router-link>
                <router-link :to="{ name: $route.name, params: { category: 'upper-rack-rules' } }"
                             class="tab-item">
                    {{ ucfirst($t("racks.upper.rules")) }}
                </router-link>
                <router-link :to="{ name: $route.name, params: { category: 'upper-rack-rasters' } }"
                             class="tab-item">
                    {{ ucfirst($t("racks.upper.raster")) }}
                </router-link>
                <router-link :to="{ name: $route.name, params: { category: 'upper-rack-raster-lengths' } }"
                             class="tab-item">
                    {{ ucfirst($t("racks.upper.raster_lengths")) }}
                </router-link>
                <router-link :to="{ name: $route.name, params: { category: 'upper-rack-colors' } }"
                             class="tab-item">
                    {{ ucfirst($t("racks.upper.colors")) }}
                </router-link>
                <router-link :to="{ name: $route.name, params: { category: 'upper-rack-end-caps' } }"
                             class="tab-item">
                    {{ ucfirst($t("racks.upper.end_caps")) }}
                </router-link>

            </div>
            <div class="search-container">
                <div class="searchbar">
                    <img src="/images/icons/search-icon.svg"
                         title="search-icon">
                    <input :placeholder="ucfirst($t('global.search'))"
                           :value="search"
                           name="search"
                           v-on:input="setSearch" />
                </div>

                <router-link
                        :to="{ name: 'upper-racks.create.inner-plates', query: $route.query, params: { category: 'upper-rack-inner-plates' }}"
                        class="btn primary"
                        v-if="this.$route.params.category === 'upper-rack-inner-plates'">
                    {{ ucfirst($t("racks.upper.new_inner_plates")) }}
                </router-link>

                <router-link
                        :to="{ name: 'upper-racks.create.outer-plates', query: $route.query, params: { category: 'upper-rack-outer-plates' }}"
                        class="btn primary"
                        v-if="this.$route.params.category === 'upper-rack-outer-plates'">
                    {{ ucfirst($t("racks.upper.new_outer_plates")) }}
                </router-link>

                <router-link
                        :to="{ name: 'upper-racks.create.rules', query: $route.query, params: { category: 'upper-rack-rules' }}"
                        class="btn primary"
                        v-if="this.$route.params.category === 'upper-rack-rules'">
                    {{ ucfirst($t("racks.upper.new_rules")) }}
                </router-link>

                <router-link
                        :to="{ name: 'upper-racks.create.rasters', query: $route.query, params: { category: 'upper-rack-rasters' }}"
                        class="btn primary"
                        v-if="this.$route.params.category === 'upper-rack-rasters'">
                    {{ ucfirst($t("racks.upper.new_rasters")) }}
                </router-link>

                <router-link
                        :to="{ name: 'upper-racks.create.raster-lengths', query: $route.query, params: { category: 'upper-rack-raster-lengths' }}"
                        class="btn primary"
                        v-if="this.$route.params.category === 'upper-rack-raster-lengths'">
                    {{ ucfirst($t("racks.upper.new_raster_lengths")) }}
                </router-link>

                <router-link
                        :to="{ name: 'upper-racks.create.colors', query: $route.query, params: { category: 'upper-rack-colors' }}"
                        class="btn primary"
                        v-if="this.$route.params.category === 'upper-rack-colors'">
                    {{ ucfirst($t("racks.upper.new_colors")) }}
                </router-link>
                <router-link
                        :to="{ name: 'upper-racks.create.end-caps', query: $route.query, params: { category: 'upper-rack-end-caps' }}"
                        class="btn primary"
                        v-if="this.$route.params.category === 'upper-rack-end-caps'">
                    {{ ucfirst($t("racks.upper.new_end_cap")) }}
                </router-link>
            </div>

            <div class="table">
                <div class="row header">
                    <div class="column pointer"
                         v-on:click="setOrderBy('name')">
                        {{ ucfirst($t("racks.upper.name")) }}
                    </div>
                </div>
                <transition-group name="list" v-if="this.$route.params.category === 'upper-rack-inner-plates'">
                    <router-link :key="upper_rack_rule.id"
                                 :to="{ name: 'upper-racks.edit.inner-plates', params: { category: 'upper-rack-inner-plates', upperRackInnerPlateId: upper_rack_rule.id } }"
                                 class="row"
                                 v-for="upper_rack_rule in upper_rack_rules">
                        <div class="column">
                            <span>
                                {{ upper_rack_rule.name }}
                            </span>
                        </div>
                    </router-link>
                </transition-group>
                <transition-group name="list" v-if="this.$route.params.category === 'upper-rack-outer-plates'">
                    <router-link :key="upper_rack_rule.id"
                                 :to="{ name: 'upper-racks.edit.outer-plates', params: { category: 'upper-rack-outer-plates', upperRackOuterPlateId: upper_rack_rule.id } }"
                                 class="row"
                                 v-for="upper_rack_rule in upper_rack_rules">
                        <div class="column">
                            <span>
                                {{ upper_rack_rule.name }}
                            </span>
                        </div>
                    </router-link>
                </transition-group>
                <transition-group name="list" v-if="this.$route.params.category === 'upper-rack-rules'">
                    <router-link :key="upper_rack_rule.id"
                                 :to="{ name: 'upper-racks.edit.rules', params: { category: 'upper-rack-rules', upperRackRuleId: upper_rack_rule.id } }"
                                 class="row"
                                 v-for="upper_rack_rule in upper_rack_rules">
                        <div class="column">
                            <span>
                                {{ upper_rack_rule.name }}
                            </span>
                        </div>
                    </router-link>
                </transition-group>
                <transition-group name="list" v-if="this.$route.params.category === 'upper-rack-rasters'">
                    <router-link :key="upper_rack_rule.id"
                                 :to="{ name: 'upper-racks.edit.rasters', params: { category: 'upper-rack-rasters', upperRackRasterId: upper_rack_rule.id } }"
                                 class="row"
                                 v-for="upper_rack_rule in upper_rack_rules">
                        <div class="column">
                            <span>
                                {{ upper_rack_rule.name }}
                            </span>
                        </div>
                    </router-link>
                </transition-group>
                <transition-group name="list" v-if="this.$route.params.category === 'upper-rack-raster-lengths'">
                    <router-link :key="upper_rack_rule.id"
                                 :to="{ name: 'upper-racks.edit.raster-lengths', params: { category: 'upper-rack-raster-lengths', upperRackRasterLengthId: upper_rack_rule.id } }"
                                 class="row"
                                 v-for="upper_rack_rule in upper_rack_rules">
                        <div class="column">
                            <span>
                                {{ upper_rack_rule.name }}
                            </span>
                        </div>
                    </router-link>
                </transition-group>
                <transition-group name="list" v-if="this.$route.params.category === 'upper-rack-end-caps'">
                    <router-link :key="upper_rack_rule.id"
                                 :to="{ name: 'upper-racks.edit.end-caps', params: { category: 'upper-rack-end-caps', upperRackEndCapId: upper_rack_rule.id } }"
                                 class="row"
                                 v-for="upper_rack_rule in upper_rack_rules">
                        <div class="column">
                            <span>
                                {{ upper_rack_rule.name }}
                            </span>
                        </div>
                    </router-link>
                </transition-group>
                <infinite-loading :distance="400"
                                  @infinite="infiniteHandler"
                                  ref="infiniteLoading"
                                  spinner="spiral">
                    <div class="no-results"
                         slot="no-more">{{ ucfirst($t("racks.upper.no_more")) }}
                    </div>
                </infinite-loading>
                <transition mode="out-in"
                            name="fade">
                    <router-view :key="$route.fullPath" />
                </transition>
            </div>
        </div>
    </transition>
</template>

<script>
    import debounce from 'lodash/debounce';

    export default {
        name: 'UpperRackIndex',
        data() {
            return {
                upper_rack_rules: [],
                search: this.$route.query.search || null,
                page: 1,
                maxPage: 1,
                orderBy: {
                    field: 'name',
                    sequence: 'DESC',
                },
            };
        },
        methods: {
            infiniteHandler($state) {
                let url = '/' + this.$route.params.category
                    + '?page=' + this.page
                    + '&order_field=' + this.orderBy.field
                    + '&order_sequence=' + this.orderBy.sequence;

                if (!!this.$route.query.search) {
                    url = url + '&search=' + this.$route.query.search;
                }

                if (this.page <= this.maxPage) {
                    this.$http.get(url)
                        .then((response) => {
                            let data = response.data;
                            this.upper_rack_rules = [...this.upper_rack_rules, ...data.data];
                            $state.loaded();
                        })
                        .catch(function (error) {
                        });

                    this.page = this.page + 1;
                } else {
                    $state.complete();
                }
            },
            setOrderBy(field) {
                if (field === this.orderBy.field) {
                    this.orderBy.sequence = this.orderBy.sequence === 'DESC' ? 'ASC' : 'DESC';
                } else {
                    this.orderBy.field = field;
                    this.orderBy.sequence = 'DESC';
                }

                this.$router.replace({
                    ...this.$route,
                    query: {
                        category: this.$route.query.category,
                        search: this.$route.query.search,
                        orderField: this.orderBy.field,
                        orderSequence: this.orderBy.sequence,
                    },
                });
            },
            setSearch: debounce(function (e) {
                this.search = e.target.value;

                this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        search: this.search,
                        orderField: this.$route.query.orderField,
                        orderSequence: this.$route.query.orderSequence,
                    },
                });
            }, 500),
        },
        beforeRouteUpdate(to, from, next) {
            this.upper_rack_rules = [];
            this.page = 1;
            this.maxPage = 1;
            this.search = null;
            this.orderBy = {
                field: 'created_at',
                sequence: 'DESC',
            };

            if (to.params.category === from.params.category) {
                this.search = to.query.search;
                this.orderBy = {
                    field: to.query.orderField,
                    sequence: to.query.orderSequence,
                };
            }

            setTimeout(() => {
                this.$refs.infiniteLoading.stateChanger.reset();
            }, 500);

            return next();
        },
        mounted() {
            this.search = this.$route.query.search || null;
        },
    };
</script>


<style scoped>

</style>