<template>
    <base-panel @overlay-clicked="$emit('overlay-clicked', $event)"
                class="sidebar-container"
                panel-class="sidebar"
                ref="panel">
        <div class="header">
            <slot name="header"></slot>
        </div>
        <form @submit.prevent="$emit('submit', $event)"
              class="body">
            <slot name="form"></slot>
        </form>
    </base-panel>
</template>

<script>
    import BasePanel from "./BasePanel";

    export default {
        name: "FormPanel",
        components: { BasePanel },
        methods: {
            open() {
                this.$refs.panel.open();
            },
            close() {
                this.$refs.panel.close();
            },
        },
        mounted() {
            this.open();
        },
        beforeRouteLeave(to, from, next) {
            this.close();
            return next();
        },
    };
</script>
