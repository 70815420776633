import UnderRackIndex from "../../../views/UnderRackIndex";
import UnderRackCreateInnerPlates from "../../../views/UnderRackCreateInnerPlates";
import UnderRackCreateOuterPlates from "../../../views/UnderRackCreateOuterPlates";
import UnderRackCreateRules from "../../../views/UnderRackCreateRules";
import UnderRackEditRules from "../../../views/UnderRackEditRules";
import UnderRackEditOuterPlates from "../../../views/UnderRackEditOuterPlates";
import UnderRackEditInnerPlates from "../../../views/UnderRackEditInnerPlates";
import UnderRackDeleteOuterPlates from "../../../views/UnderRackDeleteOuterPlates";
import UnderRackDeleteInnerPlates from "../../../views/UnderRackDeleteInnerPlates";
import UnderRackDeleteRules from "../../../views/UnderRackDeleteRules";
import auth from "../../middleware/_auth";
import role from "../../middleware/_role";
import { roles } from "../../../configuration/variables";
import ModelBoundView from "../../utils/ModelBoundView";
import UnderRackInnerPlateService from "../../../services/UnderRackInnerPlateService";
import UnderRackRulesService from "../../../services/UnderRackRulesService";
import UnderRackOuterPlateService from "../../../services/UnderRackOuterPlateService";

export default [

    {
        name: 'under-racks.index',
        path: '/under-racks/:category?',
        component: UnderRackIndex,
        children: [
            {
                name: 'under-racks.create.rules',
                path: 'create',
                component: UnderRackCreateRules,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },
            {
                name: 'under-racks.create.outer-plates',
                path: 'create',
                component: UnderRackCreateOuterPlates,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },
            {
                name: 'under-racks.create.inner-plates',
                path: 'create',
                component: UnderRackCreateInnerPlates,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },
            {
                name: 'under-racks.edit.rules',
                path: ':underRackRuleId/edit',
                component: ModelBoundView(new UnderRackRulesService, UnderRackEditRules),
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
            {
                name: 'under-racks.edit.outer-plates',
                path: ':underRackOuterPlateId/edit',
                component: ModelBoundView(new UnderRackOuterPlateService, UnderRackEditOuterPlates),
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
            {
                name: 'under-racks.edit.inner-plates',
                path: ':underRackInnerPlateId/edit',
                component: ModelBoundView(new UnderRackInnerPlateService, UnderRackEditInnerPlates),
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },


            {
                name: 'under-racks.delete.rules',
                path: ':underRackRuleId/delete',
                component: UnderRackDeleteRules,
                meta: {
                    middleware: [
                        auth,
                    ],
                },
            },
            {
                name: 'under-racks.delete.inner-plates',
                path: ':underRackInnerPlateId/delete',
                component: UnderRackDeleteInnerPlates,
                meta: {
                    middleware: [
                        auth,
                    ],
                },
            },
            {
                name: 'under-racks.delete.outer-plates',
                path: ':underRackOuterPlateId/delete',
                component: UnderRackDeleteOuterPlates,
                meta: {
                    middleware: [
                        auth,
                    ],
                },
            },
        ],
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ to, next }) => {
                    if (!to.params.category) {
                        return next({ name: 'under-racks.index', params: { category: 'under-rack-inner-plates' } });
                    }

                    return next();
                },

            ],
            forbiddenRoute: { name: 'under-racks.index' },
        },
    },
]