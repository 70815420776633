<template>
    <base-overview ref="overview"
                   slug="/frame-groups">
        <template v-slot:head>
            <h1>{{ $t("Frame groups") }}</h1>
            <router-link :to="{ name: 'window-frame-groups.create', query: $route.query }"
                         class="btn primary">
                {{ $t("Create frame group") }}
            </router-link>
        </template>
        <template v-slot:table-head>
            <div @click="$refs.overview.setOrderBy('frame_groups.name')"
                 class="column pointer">
                {{ $t("Name") }}
            </div>
        </template>
        <template v-slot:table-row="{item}">
            <div class="column">
                <span>
                    {{ item.name }}
                </span>
            </div>
        </template>
        <template v-slot:no-more>
            {{ $t('These were all frame groups') }}
        </template>
    </base-overview>
</template>

<script>
    import BaseOverview from "../components/BaseOverview";

    export default {
        name: "WindowFrameGroupIndex",
        components: { BaseOverview },
        methods: {
            getRoute(frameGroup) {
                return {
                    ...this.$route,
                    name: 'window-frame-groups.edit',
                    params: {
                        frameGroupId: frameGroup.id,
                    },
                };
            },
        },
    };
</script>
