<template>
    <div :key="$route.query.t"
         class="project-detail-container"
         v-if="!empty(project.name)">
        <div class="detail">
            <div class="project">
                <div class="header">
                    <transition name="detail">
                        <div class="name"
                             v-if="project.name !== ''">
                            <h1>
                                {{ ucfirst(project.name) }}
                            </h1>

                            <span :class="projectStatusClass"
                                  class="status">
                                {{ projectStatusName }}
                            </span>
                        </div>
                    </transition>
                    <div class="circles">
                        <div class="edit">
                            <img alt="edit"
                                 src="/images/icons/dots-icon.svg">
                            <div class="popover">
                                <div class="menu">
                                    <router-link
                                            :to="{ name: 'projects.edit', params: { projectId: project.id }, query: $route.query }"
                                            class="menu-item">
                                        {{ ucf$t("projects.edit") }}
                                    </router-link>
                                    <router-link
                                            :to="{ name: 'projects.notifications', params: { projectId: project.id }, query: $route.query }"
                                            class="menu-item"
                                            v-auth.global-admin:invert>
                                        {{ ucf$t("projects.notifications") }}
                                    </router-link>
                                    <router-link
                                            :to="{ name: 'projects.cancel', params: { projectId: project.id }, query: $route.query }"
                                            class="menu-item"
                                            v-if="! project.isCanceled">
                                        {{ ucf$t("projects.cancel") }}
                                    </router-link>
                                    <router-link
                                            :to="{ name: 'projects.reopen', params: { projectId: project.id }, query: $route.query }"
                                            class="menu-item"
                                            v-else>
                                        {{ ucf$t("projects.reopen") }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <router-link :key="`contact-${contact.id}`"
                                     :to="{ name: 'projects.share.shared-with', params: $route.params, query: $route.query }"
                                     class="user"
                                     tag="div"
                                     v-auth-or.global-admin:invert="projectHasRole(project, roles.PROJECT_OWNER)"
                                     v-for="contact in project.contacts.slice(0,3)">
                            <img :src="contact.imageUrl"
                                 class="profile"
                                 v-if="contact.imageUrl">
                            <div class="profile with-background"
                                 v-else>
                                {{ contact.firstName.charAt(0).toUpperCase() }}{{
                                contact.lastName.charAt(0).toUpperCase() }}
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'projects.share.invite', params: $route.params, query: $route.query }"
                                     class="add"
                                     tag="span"
                                     v-auth-or.global-admin:invert="projectHasRole(project, roles.PROJECT_OWNER)">
                            <img alt="invite"
                                 src="/images/icons/plus-icon.svg">
                        </router-link>
                    </div>
                </div>
                <p class="description"
                   v-if="project.description">
                    {{ project.description }}
                </p>
            </div>

            <div class="tabs">
                <div class="tab-item active">
                    {{ ucf$t("projects.products") }}
                </div>
            </div>
            <div class="product-detail">
                <transition name="detail">
                    <div :key="1"
                         class="no-products"
                         v-if="project.frames.length === 0">
                        <img alt="no project"
                             src="/images/no-project.svg">

                        <h2>
                            {{ ucf$t("projects.no_products_title") }}
                        </h2>
                        <p>
                            {{ ucf$t("projects.no_products_description") }}
                        </p>
                        <div class="button-row">
                            <button class="primary">
                                {{ ucf$t("projects.revit_sync_button") }}
                            </button>
                            <button class="secondary">
                                {{ ucf$t("projects.webshop_button") }}
                            </button>
                        </div>
                    </div>
                    <div :key="2"
                         class="products"
                         v-else>
                        <horizontal-select :options="modules"
                                           @input="updateModule"
                                           show-by="name"
                                           track-by="id"
                                           v-model="project.module">
                            <template v-slot:default="{option}">
                                <strong v-text="option.name" />
                                <span v-show="empty(project.module) || project.module.id !== option.id">
                                    € {{
                                    calculateDifference(option) > 0
                                    ? '+' + numberFormat(calculateDifference(option), 2)
                                    : numberFormat(calculateDifference(option), 2)
                                    }}
                                </span>
                            </template>
                        </horizontal-select>

                        <div class="table">
                            <div :class="{active: !empty(activeFrame) && activeFrame.id === frame.id}"
                                 @click="openFrameDetail(frame)"
                                 class="row"
                                 v-for="frame in project.frames">
                                <div class="column">
                                    {{ frame.amount }}x
                                </div>
                                <div class="column">
                                    {{ ucfirst(frame.name) }}
                                </div>
                                <div class="column">
                                    &euro; {{ numberFormat(frame.price) }}
                                </div>
                                <div class="object-detail"
                                     v-if="!empty(activeFrame) && activeFrame.id === frame.id">
                                    <div class="row"
                                         v-for="([key,value]) of Object.entries(frame.parameters)">
                                        <div class="column">
                                            {{ getParameterName(key) }}
                                        </div>
                                        <div class="column">
                                            {{ value }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="total-price">
                            &euro; {{ numberFormat(project.price) }}
                        </div>
                    </div>
                </transition>
            </div>
            <div class="bottom-bar"
                 v-auth-and.ou-approve-quotation="showAccept">
                <router-link :to="{ name: 'projects.approve', params: { project: project.id } }"
                             class="primary"
                             tag="button">
                    {{ ucf$t("projects.accept") }}
                </router-link>
            </div>
            <div class="bottom-bar disabled"
                 v-if="showDownloadDisabled">
                <label>
                    {{ ucf$t("projects.download_invoice_text") }}
                </label>
                <button class="primary">
                    {{ ucf$t("projects.download_invoice") }}
                </button>
            </div>
            <div class="bottom-bar"
                 v-if="showDownload">
                <button :class="{'disabled' : ![3,5].includes(project.projectStatus.id)}"
                        class="primary">
                    {{ ucf$t("projects.download_invoice") }}
                </button>
            </div>
        </div>
        <div class="overview">
            <transition name="detail">
                <img :src="project.imagePath"
                     v-if="project.imagePath">
            </transition>
            <div class="overlay"
                 v-if="!empty($route.query.frame)">
                <img :src="activeFrame.previewUrl">
            </div>
        </div>
        <transition mode="out-in"
                    name="fade">
            <router-view />
        </transition>
    </div>
</template>

<script>
    import HorizontalSelect from "../components/HorizontalSelect";

    export default {
        name: 'ProjectShow',
        data() {
            return {
                modules: [],
            };
        },
        components: { HorizontalSelect },
        methods: {
            updateModule(module) {
                this.$http.put(`/projects/${this.project.id}/module`, { module: module.id })
                    .catch((error) => this.handleErrors(error.response));
            },
            calculatePrice(module) {
                const { price } = this.project;
                const { percentage } = module;

                return price * (percentage / 100);
            },
            calculateDifference(module) {
                const modulePrice = this.calculatePrice(module);

                if (this.empty(this.project.module) || module.id === this.project.module.id) {
                    return modulePrice;
                }

                return modulePrice - this.calculatePrice(this.project.module);
            },
            getParameterName(key) {
                const translationKey = 'parameters.segments.option-types.' + key;
                const translation = this.ucf$t(translationKey);

                if (translation === this.ucfirst(translationKey)) {
                    return key;
                }

                return translation;
            },
            openFrameDetail(frame) {
                const route = {
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        frame: frame.id,
                    },
                };

                if (!this.empty(this.activeFrame) && this.activeFrame.id === frame.id) {
                    return;
                }

                this.$router.push(route);
            },
        },
        computed: {
            activeFrame: {
                get() {
                    if (this.empty(this.$route.query.frame)) {
                        return null;
                    }

                    return this.project.frames.find(frame => frame.id === parseInt(this.$route.query.frame, 10));
                },
            },
            projectStatusClass: {
                get() {
                    if (this.project.isCanceled) {
                        return 'canceled';
                    }

                    if (this.project.projectStatus.id !== null) {
                        let status = null;
                        switch (this.project.projectStatus.id) {
                            case 1:
                                status = 'open';
                                break;
                            case 4:
                                status = 'canceled';
                                break;
                            default:
                                status = 'accepted';
                        }

                        return status;
                    }

                    return '';
                },
            },
            projectStatusName: {
                get() {
                    if (this.project.isCanceled) {
                        return this.ucfirst('geannuleerd');
                    }

                    if (this.project.projectStatus.name.includes('akkoord')) {
                        return this.ucfirst('akkoord');
                    }

                    return this.ucfirst(this.project.projectStatus.name);
                },
            },
            showAccept: {
                get() {
                    const hasProducts = this.project.frames.length > 0;
                    const isOpen = this.project.projectStatus.id === 1;

                    return hasProducts && isOpen;
                },
            },
            showDownload: {
                get() {
                    const hasProducts = this.project.frames.length > 0;
                    const hasStatus = [3, 5].includes(this.project.projectStatus.id);

                    return hasProducts && hasStatus;
                },
            },
            showDownloadDisabled: {
                get() {
                    const hasProducts = this.project.frames.length > 0;
                    const hasStatus = this.project.projectStatus.id === 2;

                    return hasProducts && hasStatus;
                },
            },
        },
        async beforeMount() {
            const response = await this.$http.get('/modules');

            this.modules = response.data.data;
        },
    };
</script>
