import { roles } from "../../configuration/variables";
import FrameGroupService from "../../services/FrameGroupService";
import WindowFrameGroupCreate from "../../views/WindowFrameGroupCreate";
import WindowFrameGroupEdit from "../../views/WindowFrameGroupEdit";
import WindowFrameGroupEditGeneral from "../../views/WindowFrameGroupEditGeneral";
import WindowFrameGroupFrames from "../../views/WindowFrameGroupFrames";
import WindowFrameGroupIndex from "../../views/WindowFrameGroupIndex";
import auth from '../middleware/_auth';
import role from '../middleware/_role';
import ModelBoundView from "../utils/ModelBoundView";

export default [
    {
        name: 'window-frame-groups.index',
        path: '/window-frame-groups',
        component: WindowFrameGroupIndex,
        children: [
            {
                name: 'window-frame-groups.create',
                path: 'create',
                component: WindowFrameGroupCreate,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
        ],
        meta: {
            middleware: [auth, role(roles.GLOBAL_ADMIN)],
        },
    },
    {
        name: 'window-frame-groups.edit',
        path: '/window-frame-groups/:frameGroupId',
        component: ModelBoundView(new FrameGroupService, WindowFrameGroupEdit),
        children: [
            {
                name: 'window-frame-groups.edit.general',
                path: 'general',
                component: WindowFrameGroupEditGeneral,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'window-frame-groups.edit.frames',
                path: 'frames',
                component: WindowFrameGroupFrames,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
        ],
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ next }) => {
                    return next({ name: 'window-frame-groups.edit.general' });
                }
            ],
        },
    },
]
