import UpperRackIndex from "../../../views/UpperRackIndex";

import UpperRackCreateEndCaps from "../../../views/UpperRackCreateEndCaps";
import UpperRackCreateRasterLengths from "../../../views/UpperRackCreateRasterLengths";
import UpperRackCreateRasters from "../../../views/UpperRackCreateRasters";
import UpperRackCreateRules from "../../../views/UpperRackCreateRules";
import UpperRackCreateOuterPlates from "../../../views/UpperRackCreateOuterPlates";
import UpperRackCreateInnerPlates from "../../../views/UpperRackCreateInnerPlates";
import UpperRackEditRules from "../../../views/UpperRackEditRules";
import UpperRackEditInnerPlates from "../../../views/UpperRackEditInnerPlates";
import UpperRackEditOuterPlates from "../../../views/UpperRackEditOuterPlates";
import UpperRackEditRasters from "../../../views/UpperRackEditRasters";
import UpperRackEditRasterLengths from "../../../views/UpperRackEditRasterLengths";
import UpperRackEditEndCaps from "../../../views/UpperRackEditEndCaps";
import UpperRackDeleteRules from "../../../views/UpperRackDeleteRules";
import UpperRackDeleteInnerPlates from "../../../views/UpperRackDeleteInnerPlates";
import UpperRackDeleteOuterPlates from "../../../views/UpperRackDeleteOuterPlates";
import UpperRackDeleteRasters from "../../../views/UpperRackDeleteRasters";
import UpperRackDeleteRasterLengths from "../../../views/UpperRackDeleteRasterLengths";
import UpperRackDeleteEndCaps from "../../../views/UpperRackDeleteEndCaps";
import auth from "../../middleware/_auth";
import role from "../../middleware/_role";
import { roles } from "../../../configuration/variables";
import ModelBoundView from "../../utils/ModelBoundView";
import GlassApplicationService from "../../../services/GlassApplicationService";
import UpperRackRuleService from "../../../services/UpperRackRuleService";
import UpperRackInnerPlateService from "../../../services/UpperRackInnerPlateService";
import UpperRackOuterPlateService from "../../../services/UpperRackOuterPlateService";
import UpperRackRasterService from "../../../services/UpperRackRasterService";
import UpperRackRasterLengthService from "../../../services/UpperRackRasterLengthService";
import UpperRackEndCapService from "../../../services/UpperRackEndCapService";

export default [
    {
        name: 'upper-racks.index',
        path: '/upper-racks/:category?',
        component: UpperRackIndex,
        children: [

            {
                name: 'upper-racks.create.rasters',
                path: 'create',
                component: UpperRackCreateRasters,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },

            {
                name: 'upper-racks.create.end-caps',
                path: 'create',
                component: UpperRackCreateEndCaps,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },

            {
                name: 'upper-racks.create.raster-lengths',
                path: 'create',
                component: UpperRackCreateRasterLengths,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },

            {
                name: 'upper-racks.create.rules',
                path: 'create',
                component: UpperRackCreateRules,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },

            {
                name: 'upper-racks.create.outer-plates',
                path: 'create',
                component: UpperRackCreateOuterPlates,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
            {
                name: 'upper-racks.create.inner-plates',
                path: 'create',
                component: UpperRackCreateInnerPlates,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },

            {
                name: 'upper-racks.edit.rules',
                path: ':upperRackRuleId/edit',
                component: ModelBoundView(new UpperRackRuleService, UpperRackEditRules),
                meta: {
                    middleware: [
                        auth,
                    ],
                },
            },
            {
                name: 'upper-racks.edit.inner-plates',
                path: ':upperRackInnerPlateId/edit',
                component: ModelBoundView(new UpperRackInnerPlateService, UpperRackEditInnerPlates),
                meta: {
                    middleware: [
                        auth,
                    ],
                },
            },
            {
                name: 'upper-racks.edit.outer-plates',
                path: ':upperRackOuterPlateId/edit',
                component: ModelBoundView(new UpperRackOuterPlateService, UpperRackEditOuterPlates),
                meta: {
                    middleware: [
                        auth,
                    ],
                },
            },
            {
                name: 'upper-racks.edit.rasters',
                path: ':upperRackRasterId/edit',
                component: ModelBoundView(new UpperRackRasterService, UpperRackEditRasters),
                meta: {
                    middleware: [
                        auth,
                    ],
                },
            },
            {
                name: 'upper-racks.edit.raster-lengths',
                path: ':upperRackRasterLengthId/edit',
                component: ModelBoundView(new UpperRackRasterLengthService, UpperRackEditRasterLengths),

                meta: {
                    middleware: [
                        auth,
                    ],
                },
            },
            {
                name: 'upper-racks.edit.end-caps',
                path: ':upperRackEndCapId/edit',
                component: ModelBoundView(new UpperRackEndCapService, UpperRackEditEndCaps),
                meta: {
                    middleware: [
                        auth,
                    ],
                },
            },

            {
                name: 'upper-racks.delete.rules',
                path: ':upperRackRuleId/delete',
                component: UpperRackDeleteRules,
                meta: {
                    middleware: [
                        auth,
                    ],
                },
            },
            {
                name: 'upper-racks.delete.inner-plates',
                path: ':upperRackInnerPlateId/delete',
                component: UpperRackDeleteInnerPlates,
                meta: {
                    middleware: [
                        auth,
                    ],
                },
            },
            {
                name: 'upper-racks.delete.outer-plates',
                path: ':upperRackOuterPlateId/delete',
                component: UpperRackDeleteOuterPlates,
                meta: {
                    middleware: [
                        auth,
                    ],
                },
            },
            {
                name: 'upper-racks.delete.rasters',
                path: ':upperRackRasterId/delete',
                component: UpperRackDeleteRasters,
                meta: {
                    middleware: [
                        auth,
                    ],
                },
            },
            {
                name: 'upper-racks.delete.raster-lengths',
                path: ':upperRackRasterLengthId/delete',
                component: UpperRackDeleteRasterLengths,
                meta: {
                    middleware: [
                        auth,
                    ],
                },
            },
            {
                name: 'upper-racks.delete.end-caps',
                path: ':upperRackEndCapId/delete',
                component: UpperRackDeleteEndCaps,
                meta: {
                    middleware: [
                        auth,
                    ],
                },
            },
        ],
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ to, next }) => {
                    if (!to.params.category) {
                        return next({ name: 'upper-racks.index', params: { category: 'upper-rack-inner-plates' } });
                    }

                    return next();
                },

            ],
            forbiddenRoute: { name: 'upper-racks.index' },
        },
    },
]