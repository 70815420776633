import Vue from 'vue';

export default function role(_roles, invert = false) {
    let roles = _roles;

    if (!Array.isArray(_roles)) {
        roles = [_roles];
    }

    return ({ from, next }) => {
        if (!Vue.prototype.$user) {
            return next(from);
        }

        const intersection = Vue.prototype.$user.roles.filter(r => roles.includes(r.id));

        if ((intersection.length === 0) !== invert) {
            return next(from);
        }

        return next();
    };
}
