<template>
    <base-overview class="customer-container"
                   search-enabled
                   slug="customers">
        <template v-slot:head>
            <h1>
                {{ ucf$t("customers.customers") }}
            </h1>
            <router-link :to="{ name: 'customers.create', query: $route.query }"
                         class="btn primary">
                {{ ucf$t("customers.new") }}
            </router-link>
        </template>
        <template v-slot:table-head>
            <div class="column pointer">
                {{ ucf$t("customers.name") }}
            </div>
            <div class="column">
                {{ ucf$t("customers.contact") }}
            </div>
            <div class="column" />
            <div class="column" />
        </template>
        <template v-slot:table-row="{item}">
            <div class="column">
                <span>
                    {{ item.name }}
                </span>
            </div>
            <div class="column">
                <span>
                    {{ item.contactPerson.fullName }}
                </span>
            </div>
            <div class="column font-light">
                <span>
                    {{ item.contactPerson.email }}
                </span>
            </div>
            <div class="column font-light">
                <span>
                    {{ item.contactPerson.phoneNumber }}
                </span>
            </div>
        </template>
    </base-overview>
</template>

<script>
    import BaseOverview from "../components/BaseOverview";

    export default {
        name: 'CustomerIndex',
        components: { BaseOverview },
        methods: {
            getRoute(item) {
                return {
                    name: 'customers.show',
                    params: {
                        customerId: item.id,
                    },
                };
            },
        },
    };
</script>
