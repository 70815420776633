import Vue from 'vue';
import VueRouter from 'vue-router';
import { executeMiddleware } from './middleware';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact',
    routes,
});

router.beforeEach((to, from, next) => {
    executeMiddleware(to, from, next, router);
});

export default router;
