import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';
import pluralize from 'pluralize';
import Api from '../configuration/api';

export default class Service {
    constructor(name) {
        this.singular = name.replace('Service', '');
        this.name = pluralize(kebabCase(this.singular));
    }

    getEntity() {
        return camelCase(this.singular);
    }

    async index() {
        const response = await Api.get(this.name);

        return response.data;
    }

    async find(id) {
        const response = await Api.get([this.name, id].join('/'));

        return response.data.data;
    }
}
