export default function boundBy(service, vueConfig = {}) {
    const entity = service.getEntity();

    return {
        ...vueConfig,
        data() {
            const data = vueConfig.data || (() => ({}));

            return {
                ...(data()),
                [entity]: {},
            };
        },
        methods: {
            ...vueConfig.methods,
            _setEntity(data) {
                this.$set(this, entity, data);
            },

            _findEntity() {
                service.find(this.$route.params[`${entity}Id`])
                    .then((data) => {
                        this._setEntity(data);

                        if (!this.empty(vueConfig.onModelLoaded)) {
                            const onModelLoaded = vueConfig.onModelLoaded.bind(this);

                            onModelLoaded(data, entity);
                        }
                    });
            }

        },
        beforeMount() {
            this._findEntity();
            if (!this.empty(vueConfig.beforeMount)) {
                const beforeMount = vueConfig.beforeMount.bind(this);

                beforeMount();
            }
        },
        beforeRouteUpdate(to, from, next) {
            this._findEntity();

            if (!this.empty(vueConfig.beforeRouteUpdate)) {
                const beforeRouteUpdate = vueConfig.beforeRouteUpdate.bind(this);

                return beforeRouteUpdate(to, from, next);
            }

            next();
        }
    };
}
