<template>
    <div>
        <div class="search-container">
            <div class="searchbar">
                <img src="/images/icons/search-icon.svg"
                     title="search-icon">
                <input :placeholder="$t('Search..')"
                       :title="$t('Search..')"
                       name="search"
                       v-model="search">
            </div>

            <router-link :to="{ ...$route, name: 'window-frames.create.general' }"
                         class="primary"
                         tag="button">
                {{ $t('Create frame') }}
            </router-link>
        </div>

        <div class="table">
            <div class="row header">
                <div class="column">
                    {{ $t('Name') }}
                </div>
            </div>
            <transition-group name="list">
                <router-link :key="frame.id"
                             :to="{ name: 'window-frames.edit.general', params: { ...$route.params, frameId: frame.id } }"
                             class="row"
                             v-for="frame in frames">
                    <div class="column">
                        {{ frame.name }}
                    </div>
                </router-link>
            </transition-group>
            <infinite-loading :distance="400"
                              @distance="1"
                              @infinite="loadItems"
                              ref="infiniteLoading"
                              spinner="spiral">
                <div class="no-results"
                     slot="no-more">
                    <slot name="no-more" />
                </div>
            </infinite-loading>
        </div>
    </div>
</template>

<script>
    export default {
        name: "WindowFrameGroupFrames",
        data() {
            return {
                search: '',
                page: 1,
                maxPage: 2,
                frames: [],
                orderBy: {
                    field: 'created_at',
                    sequence: 'DESC',
                },
            };
        },
        methods: {
            setOrderBy(field) {
                const sequence = (field === this.orderBy.field && this.orderBy.sequence === 'DESC')
                    ? 'ASC'
                    : 'DESC';

                this.orderBy = {
                    field,
                    sequence,
                };

                this.page = 1;
                this.maxPage = 2;
                this.frames = [];
                this.$refs.infiniteLoading.stateChanger.reset();
            },
            loadItems($state) {
                this.$http
                    .get(`/frame-groups/${this.$parent.frameGroup.id}/frames`, {
                        page: this.page,
                        orderBy: this.orderBy.field,
                        sequence: this.orderBy.sequence,
                        search: this.search,
                    })
                    .then(({ data }) => {
                        if (data.data.length === 0) {
                            $state.complete();
                            return;
                        }

                        data.data.forEach((customer) => {
                            this.frames.push(customer);
                        });

                        this.maxPage = data.meta.last_page;
                        $state.loaded();
                    });
                this.page++;
            },
        },
        watch: {
            search: {
                handler() {
                    this.frames = [];
                    this.$refs.infiniteLoading.stateChanger.reset();
                    this.page = 1;
                    this.maxPage = 2;
                },
            },
        },
    };
</script>
