<template>
    <div class="parameter-form-form">
        <form-element :form-element="$parent.parameter.formElement"
                      :parameter="$parent.parameter"
                      :segments="$parent.parameter.segments"
                      :title="ucf$t('parameters.form-element')"
                      root
                      v-if="!empty($parent.parameter.formElement)" />
    </div>
</template>

<script>
    import FormElementComponent from '../components/FormElement';

    export default {
        name: 'ParameterForm',
        components: { 'form-element': FormElementComponent },
    };
</script>
