<template>
    <form-panel @overlay-clicked="close" @submit="submit" ref="panel">
        <template v-slot:header>
            <h1>
                {{ ucfirst($t('laths.grooves')) }}
            </h1>
            <div class="edit" v-on:click="toggleDrop">
                <img alt="edit"
                     src="/images/icons/dots-icon.svg" />
                <div :class="{'active' : showDrop}" class="drop">
                    <router-link
                            :to="{ name: 'laths.delete.grooves', params: $route.params }"
                            tag="button">
                        {{ ucfirst($t("laths.delete_grooves")) }}
                    </router-link>
                </div>
            </div>
            <div class="name">
                <h2>
                    {{groove.name}}
                </h2>
            </div>
        </template>
        <template v-slot:form>
            <form class="body"
                  ref="employeeForm"
                  v-on:submit.prevent="submit">
                <h2>
                    {{ ucfirst($t('settings.general_data')) }}
                </h2>
                <div class="form-group">
                    <label for="name">
                        {{ ucfirst($t("laths.name")) }}
                    </label>
                    <input id="name"
                           name="name"
                           ref="name"
                           type="text"
                           v-model="groove.name" />
                    <label for="value">
                        {{ ucfirst($t("laths.value")) }}
                    </label>
                    <input id="value"
                           name="value"
                           ref="value"
                           type="text"
                           v-model="groove.value" />

                    <label for="length">
                        {{ ucfirst($t("laths.height")) }}
                    </label>
                    <input id="length"
                           name="length"
                           ref="length"
                           type="text"
                           v-model="groove.length" />

                    <label for="useLath">
                        {{ ucfirst($t("laths.use_lath")) }}
                    </label>
                    <multiselect :close-on-select="true"
                                 :false-value="nee"
                                 :options="options"
                                 :searchable="false"
                                 :true-value="ja"
                                 deselect-label=""
                                 id="useLath"
                                 name="useLath"
                                 ref="useLath"
                                 select-label=""
                                 selected-label=""
                                 type="text"
                                 v-model="groove.useLath" />
                </div>


                <button class="primary">
                    {{ ucfirst($t('global.save_data')) }}
                </button>
            </form>
        </template>
    </form-panel>
</template>

<script>
    import moment from "moment";
    import BasePanel from "../components/BasePanel";
    import FormPanel from "../components/FormPanel";
    import { Multiselect } from "vue-multiselect";


    export default {
        name: "LathEditWidths",
        components: { FormPanel, BasePanel, Multiselect },
        data() {
            return {
                showDrop: false,
                options: ['1', '0']
            }
        },
        methods: {
            /**
             * Toggles the Dropdown.
             */
            toggleDrop() {
                this.showDrop = !this.showDrop;
            },

            close() {
                this.$router.replace({
                    name: 'laths.index',
                    params: this.$route.params,
                });
            },
            submit() {
                this.$http.post(`grooves/${this.$route.params.grooveId}/edit`, this.groove)
                    .then(() => {
                        this.$router.replace({
                            name: 'laths.index',
                            params: this.$route.params,
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        beforeMount() {
            this.$http.get('grooves')
                .then((response) => {
                    this.lathsTypes = response.data.data;
                });
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    }
</script>

<style scoped>

</style>