import { roles } from "../../configuration/variables";
import CustomerService from "../../services/CustomerService";
import UserService from "../../services/UserService";
import auth from '../middleware/_auth';
import role from '../middleware/_role';
import CustomerCreate from '../../views/CustomerCreate';
import CustomerEdit from '../../views/CustomerEdit';
import CustomerGeneral from '../../views/CustomerGeneral';
import CustomerIndex from '../../views/CustomerIndex';
import CustomerPayment from '../../views/CustomerPayment';
import CustomerShowStaff from '../../views/CustomerShowStaff';
import CustomerShowStaffCreate from '../../views/CustomerShowStaffCreate';
import CustomerShowStaffEdit from '../../views/CustomerShowStaffEdit';
import ModelBoundView from "../utils/ModelBoundView";

export default [
    {
        name: 'customers.index',
        path: '/customers',
        component: CustomerIndex,
        children: [
            {
                name: 'customers.create',
                path: 'create',
                component: CustomerCreate,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
        ],
        meta: {
            middleware: [auth, role(roles.GLOBAL_ADMIN)],
        },
    },
    {
        name: 'customers.show',
        path: '/customers/:customerId',
        component: ModelBoundView(new CustomerService, CustomerEdit),
        children: [
            {
                name: 'customers.show.general',
                path: 'general',
                component: CustomerGeneral,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'customers.show.staff',
                path: 'staff',
                component: CustomerShowStaff,
                children: [
                    {
                        name: 'customers.show.staff.create',
                        path: 'create',
                        component: CustomerShowStaffCreate,
                        meta: {
                            middleware: [auth, role(roles.GLOBAL_ADMIN)],
                        },
                    },
                    {
                        name: 'customers.show.staff.edit',
                        path: ':userId/edit',
                        component: ModelBoundView(new UserService, CustomerShowStaffEdit),
                        meta: {
                            middleware: [auth, role(roles.GLOBAL_ADMIN)],
                        },
                    },
                ],
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'customers.show.payment',
                path: 'payment',
                component: ModelBoundView(new CustomerService, CustomerPayment),
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
        ],
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ to, next }) => {
                    if (to.name === 'customers.show') {
                        return next({
                            name: 'customers.show.general',
                            params: to.params,
                            query: to.query,
                        });
                    }

                    return next();
                },
            ],
        },
    },
];
