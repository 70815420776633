<template>
    <div class="frame-formulas">
        <div class="left">
            <fbx-viewer :background="0xFFFFFF"
                        :fbx="$parent.frame.fbxTemporaryUrl"
                        :on-object-hovered="onObjectHovered"
                        :on-object-unhovered="onObjectUnhovered"
                        @object-click="onObjectClicked"
                        @object-loaded="onObjectLoaded"
                        ref="fbxViewer"
                        v-if="!empty($parent.frame.fbxTemporaryUrl)" />
        </div>
        <div class="right"
             v-if="!empty(object)">
            <h1>
                {{ name }}
            </h1>
            <span class="sub-title">
                {{ $t('Revit ID') }}: {{ revitId }}
            </span>
            <div class="formulas">
                <div class="formula"
                     v-for="(formula, index) in formulas">
                    <div class="head">
                        <h2 v-text="`Formule ${index + 1}`" />
                        <button @click="removeFormula(index)"
                                class="remove"
                                type="button">
                            <img :alt="$t('Remove formula')"
                                 src="/images/icons/close-icon-2.svg">
                        </button>
                    </div>
                    <div class="form-group">
                        <label for="type">
                            {{ $t('Calculation') }}
                        </label>
                        <select id="type"
                                name="type"
                                v-model="formula.formulaType">
                            <option :value="undefined"
                                    disabled>
                                {{ $t('Choose a calculation') }}
                            </option>
                            <option :value="formulaTypes.PRICE_MATRIX"
                                    v-if="priceMatrices.length > 0 && $parent.frame.parameters.length > 0">
                                {{ $t('Calculation based on a price matrix') }}
                            </option>
                            <option :value="formulaTypes.FORMULA"
                                    v-if="$parent.frame.parameters.length > 0">
                                {{ $t('Calculation based on a formula') }}
                            </option>
                            <option :value="formulaTypes.FIXED">
                                {{ $t('Fixed price') }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group"
                         v-if="formula.formulaType === formulaTypes.FIXED">
                        <label for="fixed">
                            {{ $t('Price') }}
                        </label>
                        <input id="fixed"
                               name="fixed"
                               type="text"
                               v-model="formula.price">
                    </div>
                    <div class="form-group"
                         v-if="formula.formulaType === formulaTypes.FORMULA">
                        <label for="formula">
                            {{ $t('Formula') }}
                        </label>
                        <formula-input :parameters="$parent.frame.parameters"
                                       id="formula"
                                       quick-filter=""
                                       v-model="formula.formula" />
                    </div>
                    <div v-if="formula.formulaType === formulaTypes.PRICE_MATRIX">
                        <div class="form-group">
                            <label for="priceMatrix">
                                {{ $t('Price matrix') }}
                            </label>
                            <select id="priceMatrix"
                                    name="priceMatrix"
                                    v-model="formula.priceMatrix">
                                <option :value="undefined"
                                        disabled>
                                    {{ $t('Select a price matrix') }}
                                </option>
                                <option :value="priceMatrix.id"
                                        v-for="priceMatrix of priceMatrices">
                                    {{ priceMatrix.name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="widthFormula">
                                {{ $t('Width formula') }}
                            </label>
                            <formula-input :parameters="$parent.frame.parameters"
                                           id="widthFormula"
                                           quick-filter="breedte"
                                           v-model="formula.widthFormula" />
                        </div>
                        <div class="form-group">
                            <label for="heightFormula">
                                {{ $t('Height formula') }}
                            </label>
                            <formula-input :parameters="$parent.frame.parameters"
                                           id="heightFormula"
                                           quick-filter="hoogte"
                                           v-model="formula.heightFormula" />
                        </div>
                    </div>
                </div>
                <button @click="addFormula"
                        class="primary add"
                        type="button">
                    {{ $t('Add formula') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import FbxViewer from '../components/FbxViewer';
    import FormulaInput from '../components/FormulaInput';
    import Formula from "../models/Formula";

    export default {
        name: 'WindowFrameFormulas',
        components: { FbxViewer, FormulaInput },
        data() {
            return {
                hoveredObject: {},
                currentObject: {},
                priceMatrices: [],
            };
        },
        methods: {
            onObjectClicked(fbxObject) {
                this.currentObject = {
                    object: fbxObject.object,
                    originalColor: fbxObject.object.material.color.getHex(),
                };

                fbxObject.object.material.color.setHex(0xFE81C1);
            },
            onObjectHovered(fbxObject) {
                if (!this.empty(this.hoveredObject.object) && this.hoveredObject.object.uuid !== fbxObject.object.uuid) {
                    this.onObjectUnhovered();
                }

                this.hoveredObject = {
                    object: fbxObject.object,
                    originalColor: fbxObject.object.material.color.getHex(),
                };

                fbxObject.object.material.color.setHex(0xFE81C1);
            },
            onObjectUnhovered() {
                if (this.empty(this.currentObject.object) || this.hoveredObject.object.uuid !== this.currentObject.object.uuid) {
                    this.hoveredObject.object.material.color.setHex(this.hoveredObject.originalColor);
                }
                this.hoveredObject = {};
            },
            onObjectLoaded(fbxObject) {

            },
            formulaIsOfObject(formula, object) {
                return parseInt(formula.objectUuid, 10) === object.ID;
            },
            getFormulas(object) {
                return this.$parent.frame.formulas.filter(formula => this.formulaIsOfObject(formula, object));
            },
            addFormula() {
                this.$parent.frame.formulas.push(new Formula(this.currentObject.object));
            },
            removeFormula(index) {
                const removedFormula = this.formulas[index];
                const actualIndex = this.$parent.frame.formulas.findIndex(formula => formula.uuid === removedFormula.uuid);

                this.$parent.frame.formulas.splice(actualIndex, 1);
            },
        },
        computed: {
            object: {
                get() {
                    return this.currentObject.object;
                },
            },
            name: {
                get() {
                    return this.object.name.split('_')[1];
                },
            },
            revitId: {
                get() {
                    return this.object.name.split('_')[2];
                },
            },
            symbol: {
                get() {
                    return this.object.name.split('_')[0];
                },
            },
            formulas: {
                get() {
                    return this.getFormulas(this.object);
                },
            },
        },
        beforeMount() {
            this.$http.get('/price-matrices')
                .then((response) => {
                    this.priceMatrices = response.data.data;
                });
        },
        mounted() {
            window.scrollTo(0, document.body.scrollHeight);
        },
    };
</script>
