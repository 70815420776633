import authRoutes from './_auth';
import customers from './_customers';
import windowFrames from './_window-frames';
import windowFrameGroups from './_window-frame-groups';
import notifications from './_notifications';
import onboarding from './_onboarding';
import parameters from './_parameters';
import priceMatrices from './_price-matrices';
import projects from './_projects';
import settings from './_settings';
import modules from './_modules';
import glass from './revit-management/_glass';
import frames from './revit-management/_frames';
import laths from './revit-management/_laths';
import underRacks from './revit-management/_under-racks';
import upperRacks from './revit-management/_upper-racks';

export default [
    // Default redirect
    {
        path: '/',
        redirect: '/projects',
    },

    ...authRoutes,
    ...modules,
    ...customers,
    ...windowFrames,
    ...windowFrameGroups,
    ...notifications,
    ...onboarding,
    ...parameters,
    ...priceMatrices,
    ...projects,
    ...settings,
    ...glass,
    ...frames,
    ...laths,
    ...underRacks,
    ...upperRacks,
];
