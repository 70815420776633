import GlassIndex from "../../../views/GlassIndex";
import GlassCreateTypes from "../../../views/GlassCreateTypes";
import GlassCreateApplications from "../../../views/GlassCreateApplications";
import GlassEditTypes from "../../../views/GlassEditTypes";
import GlassEditApplications from "../../../views/GlassEditApplications";
import GlassDeleteTypes from "../../../views/GlassDeleteTypes";
import GlassDeleteApplications from "../../../views/GlassDeleteApplications";
import role from "../../middleware/_role";
import auth from "../../middleware/_auth";
import { roles } from "../../../configuration/variables";
import ModelBoundView from "../../utils/ModelBoundView";
import GlassTypeService from "../../../services/GlassTypeService";
import GlassApplicationService from "../../../services/GlassApplicationService";

export default [
    {
        name: 'glass.index',
        path: '/glass/:category?',
        component: GlassIndex,
        children: [
            {
                name: 'glass.create.types',
                path: 'create',
                component: GlassCreateTypes,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },

            {
                name: 'glass.create.applications',
                path: 'create',
                component: GlassCreateApplications,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },

            {
                name: 'glass.edit.types',
                path: ':glassTypeId/edit',
                component: ModelBoundView(new GlassTypeService, GlassEditTypes),
                meta: {
                    middleware: [
                        auth,
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },

            {
                name: 'glass.edit.applications',
                path: ':glassApplicationId/edit',
                component: ModelBoundView(new GlassApplicationService, GlassEditApplications),
                meta: {
                    middleware: [
                        auth,
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },

            {
                name: 'glass.delete.types',
                path: ':glassTypeId?/delete',
                component: GlassDeleteTypes,
                meta: {
                    middleware: [
                        auth,
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },

            {
                name: 'glass.delete.applications',
                path: ':glassApplicationId?/delete',
                component: GlassDeleteApplications,
                meta: {
                    middleware: [
                        auth,
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },
        ],
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ to, next }) => {
                    if (!to.params.category) {
                        return next({ name: 'glass.index', params: { category: 'glass-types' } });
                    }

                    return next();
                },

            ],
            forbiddenRoute: { name: 'glass.index' },
        },
    },
]