<template>
    <div class="frame-families">
        <div class="family"
             v-for="(family, familyIndex) in $parent.frame.families">
            <div class="head">
                <h2 v-text="$t('Family') + ' ' + (familyIndex + 1)" />
                <button @click="$parent.frame.families.splice(familyIndex, 1)"
                        class="remove"
                        type="button">
                    <img :alt="$t('Remove family')"
                         src="/images/icons/close-icon-2.svg">
                </button>
            </div>
            <div class="form-group">
                <label for="name">
                    {{ $t("Name") }}
                </label>
                <input id="name"
                       name="name"
                       ref="name"
                       type="text"
                       v-model="family.name">
            </div>
            <div class="form-group">
                <label for="preview">
                    {{ $t("Revit family") }}
                </label>
                <file-input :create-base64="false"
                            :default="null"
                            file-type=".rfa"
                            v-model="family.rfaFile">
                    <template #default="{image}">
                        <img alt="Checkmark Icon"
                             class="checkmark"
                             src="/images/icons/check-pink-icon.svg"
                             v-if="image || !!$parent.frame.sourceUrl">
                        <div class="img-placeholder"
                             v-else />
                        <span>
                            {{ $t('Upload RFA') }}
                        </span>
                    </template>
                </file-input>
            </div>
            <div class="head">
                <h3>
                    {{ $t('Conditions') }}
                </h3>
                <button @click="family.conditions.push({})"
                        class="primary"
                        type="button">
                    {{ $t('Add condition') }}
                </button>
            </div>
            <div class="conditions">
                <div class="condition"
                     v-for="(condition, conditionIndex) in family.conditions">
                    <div class="head">
                        <h4 v-text="$t('Condition') + ' ' + (conditionIndex + 1)" />
                        <button @click="family.conditions.splice(conditionIndex, 1)"
                                class="remove"
                                type="button">
                            <img :alt="$t('Remove family')"
                                 src="/images/icons/close-icon-2.svg">
                        </button>
                    </div>
                    <div class="form-group">
                        <label for="parameter">
                            {{ $t("Parameter") }}
                        </label>
                        <select id="parameter"
                                name="parameter"
                                v-model="condition.parameter">
                            <option :value="undefined">
                                {{ $t('Select a parameter') }}
                            </option>
                            <option :value="parameter"
                                    v-for="parameter of parameters">
                                {{ parameter.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="operator">
                            {{ $t("Operator") }}
                        </label>
                        <select id="operator"
                                name="operator"
                                v-model="condition.operator">
                            <option :value="undefined">
                                {{ $t('Select an operator') }}
                            </option>
                            <option :value="operator"
                                    v-for="operator of operators">
                                {{ $t(operator) }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="value">
                            {{ $t("Value") }}
                        </label>
                        <input id="value"
                               name="value"
                               ref="value"
                               type="text"
                               v-model="condition.value">
                    </div>
                </div>
            </div>
        </div>
        <button @click="$parent.frame.families.push({conditions:[]})"
                class="primary"
                type="button">
            {{ $t('Add family') }}
        </button>
    </div>
</template>

<script>
    import FileInput from "../components/FileInput";

    export default {
        name: "WindowFrameFamilies",
        components: { FileInput },
        computed: {
            operators: {
                get() {
                    return [
                        '=',
                        '>',
                        '>=',
                        '<',
                        '<=',
                        '!=',
                    ];
                },
            },
            parameters: {
                get() {
                    return this.$parent.parameters;
                },
            },
        },
    };
</script>
