<template>
    <base-panel :panel-class="`modal ${modalClass}`"
                @overlay-clicked="close"
                ref="panel">
        <div class="header">
            <slot name="header"></slot>
            <div @click="close"
                 class="close">
                <img alt="close"
                     src="/images/icons/close-icon.svg">
            </div>
        </div>
        <div class="body">
            <slot></slot>
        </div>
        <div class="footer"
             v-if="!empty($slots.footer)">
            <slot name="footer"></slot>
        </div>
    </base-panel>
</template>

<script>
    import BasePanel from './BasePanel';

    export default {
        name: "BaseModal",
        components: { BasePanel },
        props: {
            modalClass: {
                required: false,
                type: String,
                default() {
                    return '';
                },
            },
        },
        methods: {
            open() {
                this.$refs.panel.open();
            },
            close() {
                this.$refs.panel.close();
            },
        },
    };
</script>
