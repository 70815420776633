import { roles } from "../../configuration/variables";
import FrameService from "../../services/FrameService";
import WindowFrameCreate from '../../views/WindowFrameCreate';
import WindowFrameEdit from "../../views/WindowFrameEdit";
import WindowFrameFormulas from '../../views/WindowFrameFormulas';
import WindowFrameGeneral from '../../views/WindowFrameGeneral';
import WindowFrameFamilies from "../../views/WindowFrameFamilies";
import WindowFrameParameters from '../../views/WindowFrameParameters';
import auth from '../middleware/_auth';
import role from '../middleware/_role';
import ModelBoundView from "../utils/ModelBoundView";

export default [
    {
        name: 'window-frames.create',
        path: '/window-frame-group/:frameGroupId/window-frames/create',
        component: WindowFrameCreate,
        children: [
            {
                name: 'window-frames.create.general',
                path: 'general',
                component: WindowFrameGeneral,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'window-frames.create.parameters',
                path: 'parameters',
                component: WindowFrameParameters,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
            {
                name: 'window-frames.create.formulas',
                path: 'formulas',
                component: WindowFrameFormulas,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                        ({ from, next }) => {
                            if (from.name === null) {
                                return next({ name: 'window-frames.create.general' });
                            }

                            return next();
                        }
                    ],
                },
            },
            {
                name: 'window-frames.create.families',
                path: 'families',
                component: WindowFrameFamilies,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
        ],
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
            ],
        },
    },
    {
        name: 'window-frames.edit',
        path: '/window-frames/:frameId',
        component: ModelBoundView(new FrameService, WindowFrameEdit),
        children: [
            {
                name: 'window-frames.edit.general',
                path: 'general',
                component: WindowFrameGeneral,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'window-frames.edit.parameters',
                path: 'parameters',
                component: WindowFrameParameters,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'window-frames.edit.formulas',
                path: 'formulas',
                component: WindowFrameFormulas,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'window-frames.edit.families',
                path: 'families',
                component: WindowFrameFamilies,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
        ],
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ to, next }) => {
                    return next({ ...to, name: 'window-frames.edit.general' });
                },
            ],
        },
    },
]
