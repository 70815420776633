<template>
    <form-panel @overlay-clicked="close" @submit="submit" ref="panel">
        <template v-slot:header>
            <h1>
                {{ ucfirst($t('laths.cavity_lath_width')) }}
            </h1>
            <div class="edit" v-on:click="toggleDrop">
                <img alt="edit"
                     src="/images/icons/dots-icon.svg" />
                <div :class="{'active' : showDrop}" class="drop">
                    <router-link
                            :to="{ name: 'laths.delete.widths', params: $route.params }"
                            tag="button">
                        {{ ucfirst($t("laths.delete_widths")) }}
                    </router-link>
                </div>
            </div>
            <div class="name">
                <h2>
                    {{lathWidth.name}}
                </h2>
            </div>
        </template>
        <template v-slot:form>
            <form class="body"
                  ref="employeeForm"
                  v-on:submit.prevent="submit">
                <h2>
                    {{ ucfirst($t('settings.general_data')) }}
                </h2>
                <div class="form-group">
                    <label for="name">
                        {{ ucfirst($t("laths.name")) }}
                    </label>
                    <input id="name"
                           name="name"
                           ref="name"
                           type="text"
                           v-model="lathWidth.name" />
                    <label for="value">
                        {{ ucfirst($t("laths.value")) }}
                    </label>
                    <input id="value"
                           name="value"
                           ref="value"
                           type="text"
                           v-model="lathWidth.value" />
                    <label for="width">
                        {{ ucfirst($t("laths.width")) }}
                    </label>
                    <input id="width"
                           name="width"
                           ref="width"
                           type="text"
                           v-model="lathWidth.width" />
                    <label for="length">
                        {{ ucfirst($t("laths.height")) }}
                    </label>
                    <input id="length"
                           name="length"
                           ref="length"
                           type="text"
                           v-model="lathWidth.length" />
                </div>

                <button class="primary">
                    {{ ucfirst($t('global.save_data')) }}
                </button>
            </form>
        </template>
    </form-panel>
</template>

<script>
    import moment from "moment";
    import BasePanel from "../components/BasePanel";
    import FormPanel from "../components/FormPanel";


    export default {
        name: "LathEditWidths",
        components: { FormPanel, BasePanel },
        data() {
            return {
                showDrop: false,
            }
        },
        methods: {
            /**
             * Toggles the Dropdown.
             */
            toggleDrop() {
                this.showDrop = !this.showDrop;
            },

            close() {
                this.$router.replace({
                    name: 'laths.index',
                    params: this.$route.params,
                });
            },
            submit() {
                this.$http.post(`${this.$route.params.category}/${this.$route.params.lathWidthId}/edit`, this.lathWidth)
                    .then(() => {
                        this.$router.replace({
                            name: 'laths.index',
                            params: this.$route.params,
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        beforeMount() {
            this.$http.get('lath-widths')
                .then((response) => {
                    this.lathsTypes = response.data.data;
                });
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    }
</script>

<style scoped>

</style>