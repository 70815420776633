<template>
    <base-panel @overlay-clicked="close"
                class="sidebar-container"
                panel-class="sidebar price-matrix"
                ref="panel">
        <div class="header">
            <h1>
                {{ ucf$t('price-matrices.new') }}
            </h1>
        </div>
        <form class="body"
              ref="employeeForm"
              @submit.prevent="submit">
            <h2>
                {{ ucf$t('settings.general_data') }}
            </h2>
            <div class="form-group">
                <label for="name">
                    {{ ucf$t("price-matrices.name") }}
                </label>
                <input id="name"
                       name="name"
                       ref="name"
                       type="text"
                       v-model="formData.name">
            </div>

            <div class="form-group">
                <label>
                    {{ ucf$t("price-matrices.matrix") }}
                </label>
                <price-matrix-input ref="matrixField"
                                    v-model="formData.matrix" />
            </div>

            <button class="primary">
                {{ ucf$t('price-matrices.new') }}
            </button>
        </form>
    </base-panel>
</template>

<script>
    import moment from 'moment';
    import BasePanel from '../components/BasePanel';
    import PriceMatrixInput, {column, row} from '../components/PriceMatrixInput';

    export default {
        name: 'PriceMatrixCreate',
        components: { PriceMatrixInput, BasePanel },
        data() {
            return {
                formData: {
                    matrix: {
                        cells: [
                            {
                                price: null,
                                min_width: 0,
                                max_width: 500,
                                min_height: 0,
                                max_height: 500,
                            },
                        ],
                        rows: [
                            row(0, 500),
                        ],
                        columns: [
                            column(0, 500),
                        ],
                    },
                },
            };
        },
        methods: {
            submit() {
                this.$http.post('/price-matrices/create', this.formData)
                    .then(() => {
                        this.$router.replace({
                            name: 'price-matrices.index',
                            params: this.$route.params,
                            query: {
                                ...this.$route.query,
                                t: moment().format('HHmmssSSS'),
                            },
                        });
                    });
            },
            close() {
                this.$router.replace({
                    name: 'price-matrices.index',
                    query: this.$route.query,
                });
            },
        },
        mounted() {
            this.$refs.panel.open();

            this.$refs.matrixField.$refs.panel.remount();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    };
</script>
