<template>
    <div class="onboarding">
        <div class="information">
            <transition name="onboarding-0">
                <div :key="0"
                     v-if="step === 0">
                    <div class="left">
                        <h1 class="big-margin">
                            {{ ucf$t("onboarding.title0") }}
                        </h1>
                        <div class="line">
                            <div class="dark-line" />
                        </div>
                    </div>
                </div>
            </transition>

            <transition name="onboarding-1">
                <div :key="1"
                     v-if="step === 1">
                    <div class="left">
                        <h1>
                            {{ ucf$t("onboarding.title1") }}
                        </h1>
                        <p>
                            {{ ucf$t("onboarding.description1") }}
                        </p>
                        <div class="items"
                             v-if="step !== 0">
                            <span class="dot active" />
                            <span class="dot" />
                            <span class="dot" />
                            <div class="arrow"
                                 @click="nextStep">
                                <img alt="next"
                                     src="/images/icons/arrow-right-icon.svg">
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="circle">
                            <div class="inner-circle" />
                        </div>
                        <img class="man"
                             src="/images/man.svg">
                        <img class="woman"
                             src="/images/woman.svg">
                    </div>
                </div>
            </transition>
            <transition name="onboarding-2">
                <div :key="2"
                     v-if="step === 2">
                    <div class="left">
                        <h1>
                            {{ ucf$t("onboarding.title2") }}
                        </h1>
                        <p>
                            {{ ucf$t("onboarding.description2") }}
                        </p>
                        <div class="items"
                             v-if="step !== 0">
                            <span class="dot" />
                            <span class="dot active" />
                            <span class="dot" />
                            <div class="arrow"
                                 @click="nextStep">
                                <img alt="next"
                                     src="/images/icons/arrow-right-icon.svg">
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="circle">
                            <div class="inner-circle" />
                        </div>
                        <img class="pie-chart"
                             src="/images/pie-chart.svg">
                        <img class="mountains"
                             src="/images/mountain.svg">
                        <img class="list"
                             src="/images/list.svg">
                    </div>
                </div>
            </transition>
            <transition name="onboarding-3">
                <div :key="3"
                     v-if="step === 3">
                    <div class="left">
                        <h1>
                            {{ ucf$t("onboarding.title3") }}
                        </h1>
                        <p>
                            {{ ucf$t("onboarding.description3") }}
                        </p>
                        <div class="items"
                             v-if="step !== 0">
                            <span class="dot" />
                            <span class="dot" />
                            <span class="dot active" />
                            <div class="arrow"
                                 @click="nextStep">
                                <img alt="next"
                                     src="/images/icons/arrow-right-icon.svg">
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="circle">
                            <div class="inner-circle" />
                        </div>
                        <img class="woman-2"
                             src="/images/woman-2.svg">
                        <img class="to-do-list"
                             src="/images/to-do-list.svg">
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'OnboardingShow',
        data() {
            return {
                step: 0,
            };
        },
        mounted() {
            const self = this;
            setTimeout(() => {
                self.nextStep();
            }, 3000);
        },
        methods: {
            nextStep() {
                if (this.step < 3) {
                    this.step++;
                } else {
                    this.closeOnboarding();
                }
            },
            closeOnboarding() {
                this.$router.push({ name: 'projects.index', params: { status: 1 } });
            },
        },
    };
</script>
