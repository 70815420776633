<template>
    <form-panel @overlay-clicked="close" @submit="submit" ref="panel">
        <template v-slot:header>
            <h1>
                {{ ucfirst($t('frames.weights')) }}
            </h1>
            <div class="edit" v-on:click="toggleDrop">
                <img alt="edit" src="/images/icons/dots-icon.svg" />
                <div :class="{'active' : showDrop}" class="drop">
                    <router-link :to="{ name: 'frames.delete.weights', params: $route.params }" tag="button">
                        {{ ucfirst($t("frames.delete_weights")) }}
                    </router-link>
                </div>
            </div>
            <div class="name">
                <h2>
                    {{frameWeight.name}}
                </h2>
            </div>
        </template>
        <template v-slot:form>
            <h2>
                {{ ucfirst($t('settings.general_data')) }}
            </h2>
            <div class="form-group">
                <label for="name">
                    {{ ucfirst($t("frames.name")) }}
                </label>
                <input id="name"
                       name="name"
                       ref="name"
                       type="text"
                       v-model="frameWeight.name" />
                <label for="value">
                    {{ ucfirst($t("glass.value")) }}
                </label>
                <input id="value"
                       name="value"
                       ref="value"
                       type="text"
                       v-model="frameWeight.value" />
            </div>

            <button class="primary">
                {{ ucfirst($t('global.save_data')) }}
            </button>

        </template>

    </form-panel>
</template>

<script>
    import moment from "moment";
    import BasePanel from "../components/BasePanel";
    import FormPanel from "../components/FormPanel";

    export default {
        name: "FrameEditWeights",
        components: { FormPanel, BasePanel },
        data() {
            return {
                showDrop: false,
            }
        },
        methods: {
            /**
             * Toggles the Dropdown.
             */
            toggleDrop() {
                this.showDrop = !this.showDrop;
            },

            close() {
                this.$router.replace({
                    name: 'frames.index',
                    params: this.$route.params,
                });
            },
            submit() {
                this.$http.post(`${this.$route.params.category}/${this.$route.params.frameWeightId}/edit`, this.frameWeight)
                    .then(() => {
                        this.$router.replace({
                            name: 'frames.index',
                            params: this.$route.params,
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        beforeMount() {
            this.$http.get('/frame-weights')
                .then((response) => {
                    this.framesTypes = response.data.data;
                });
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    }
</script>

<style scoped>

</style>