<template>
    <div class="profile-container"
         v-if="!empty(value)">
        <div class="header">
            <h1>{{ ucf$t("profile.profile") }}</h1>
        </div>
        <div class="tabs">
            <div class="tab-item active">
                {{ ucf$t("profile.detail") }}
            </div>
        </div>
        <form @change="submit"
              enctype="multipart/form-data"
              ref="profileForm">
            <div class="form-row">
                <div class="form-group">
                    <label for="firstName">
                        {{ ucf$t("user.first_name") }}
                    </label>
                    <input id="firstName"
                           name="firstName"
                           ref="firstName"
                           type="text"
                           v-model="value.firstName">
                </div>
                <div class="form-group">
                    <label for="middleName">
                        {{ ucf$t("user.middle_name") }}
                    </label>
                    <input id="middleName"
                           name="middleName"
                           ref="middleName"
                           type="text"
                           v-model="value.middleName">
                </div>
                <div class="form-group">
                    <label for="lastName">
                        {{ ucf$t("user.last_name") }}
                    </label>
                    <input id="lastName"
                           name="lastName"
                           ref="lastName"
                           type="text"
                           v-model="value.lastName">
                </div>
            </div>
            <div class="form-row">
                <div class="form-group">
                    <label for="phoneNumber">
                        {{ ucf$t("user.phone_number") }}
                    </label>
                    <input id="phoneNumber"
                           name="phoneNumber"
                           ref="phoneNumber"
                           type="text"
                           v-model="value.phoneNumber">
                </div>
                <div class="form-group">
                    <label for="email">
                        {{ ucf$t("user.email") }}
                    </label>
                    <input id="email"
                           name="email"
                           ref="email"
                           type="text"
                           v-model="value.email">
                </div>
            </div>
            <file-input class="file-upload"
                        default="/"
                        v-model="image">
                <template v-slot:default="{image,isLoading}">
                    <img :src="image"
                         class="profile"
                         v-if="!image.isLoading && image !== '/'">
                    <div class="profile with-background"
                         v-else>
                        {{ value.firstName.charAt(0).toUpperCase() }}
                        {{ value.lastName.charAt(0).toUpperCase() }}
                    </div>
                    <label>
                        {{ ucf$t("user.add_image") }}
                    </label>
                </template>
            </file-input>
        </form>
    </div>
</template>

<script>
    import FileInput from '../components/FileInput';

    export default {
        name: 'ProfileShow',
        components: { FileInput },
        data() {
            return {
                image: null,
                value: null,
            };
        },
        methods: {
            submit: debounce(function () {
                this.$http.post('/users/current', this.value);
            }, 150),
            setImage(image) {
                if (!image) {
                    return;
                }

                if (image.startsWith('/') || image.startsWith('http')) {
                    this.image = image;
                    return;
                }

                this.image = `/${image}`;
            },
        },
        mounted() {
            this.setImage(this.authUser.imageUrl);
            this.value = this.deepClone(this.authUser);
        },
    };
</script>
