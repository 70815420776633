import { roles } from "../../configuration/variables";
import ModuleService from "../../services/ModuleService";
import ModuleCreate from "../../views/ModuleCreate";
import ModuleEdit from "../../views/ModuleEdit";
import ModuleIndex from "../../views/ModuleIndex";
import auth from "../middleware/_auth";
import role from "../middleware/_role";
import ModelBoundView from "../utils/ModelBoundView";

export default [
    {
        name: 'modules.index',
        path: '/modules',
        component: ModuleIndex,
        children: [
            {
                name: 'modules.create',
                path: 'create',
                component: ModuleCreate,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'modules.edit',
                path: ':moduleId/edit',
                component: ModelBoundView(new ModuleService, ModuleEdit),
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
        ],
        meta: {
            middleware: [auth, role(roles.GLOBAL_ADMIN)],
        },
    },
];
