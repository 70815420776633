<template>
    <form-panel @overlay-clicked="close"
                @submit="submit"
                ref="formPanel">
        <template v-slot:header>
            <h1>
                {{ $t('Create module') }}
            </h1>
        </template>
        <template v-slot:form>
            <div class="form-group">
                <label for="name">
                    {{ $t("Name") }}
                </label>
                <input id="name"
                       name="name"
                       ref="name"
                       type="text"
                       v-model="module.name">
            </div>
            <div class="form-group">
                <label for="percentage">
                    {{ $t("Percentage") }}
                </label>
                <input id="percentage"
                       name="percentage"
                       ref="percentage"
                       type="text"
                       v-model="module.percentage">
            </div>
            <button class="primary"
                    type="submit">
                {{ $t('Store') }}
            </button>
        </template>
    </form-panel>
</template>

<script>
    import FormPanel from "../components/FormPanel";

    export default {
        name: "ModuleEdit",
        components: { FormPanel },
        methods: {
            close() {
                this.$refs.formPanel.close();
                this.$router.replace({
                    name: 'modules.index',
                });
            },
            submit() {
                this.$http.post(`modules/${this.module.id}/edit`, this.module)
                    .then(this.close)
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
    };
</script>
