<template>
    <div class="settings-container">
        <div class="header">
            <h1>{{ customer.name }}</h1>
            <div class="btn secondary"
                 v-on:click="destroyCustomer">
                {{ ucf$t("customers.delete") }}
            </div>
        </div>

        <div class="tabs">
            <router-link :to="{ name: 'customers.show.general', params: $route.params, query: $route.query }"
                         class="tab-item">
                {{ ucf$t('settings.general_data') }}
            </router-link>
            <router-link :to="{ name: 'customers.show.staff', params: $route.params, query: $route.query }"
                         class="tab-item">
                {{ ucf$t('settings.staff_members') }}
            </router-link>
            <router-link :to="{ name: 'customers.show.payment', params: $route.params, query: $route.query }"
                         class="tab-item">
                {{ ucf$t('customers.payment_details') }}
            </router-link>
        </div>

        <confirm-modal :cancel-text="$t('Cancel')"
                       :confirm-text="$t('Confirm')"
                       :question="$t('Are you sure you want to delete this customer?')"
                       :title="$t('Delete customer')"
                       ref="deleteCustomerModal">
        </confirm-modal>

        <transition mode="out-in"
                    name="fade">
            <router-view />
        </transition>
    </div>
</template>

<script>
    import ConfirmModal from "../components/ConfirmModal";

    export default {
        name: 'CustomerEdit',
        components: { ConfirmModal },
        data() {
            return {
                contacts: [],
                countries: [],
            };
        },
        methods: {
            destroyCustomer() {
                this.$refs.deleteCustomerModal.confirm()
                    .then(async () => {
                        await this.$http.delete(`/customers/${this.customer.id}`);

                        await this.$router.replace({
                            name: 'customers.index',
                        });
                    });
            },
            loadContacts() {
                this.$http.get('/customers/me/users').then((response) => {
                    this.contacts = response.data.data;
                });
            },
            init() {
                this.loadContacts();
                this.$http.get('/countries').then((response) => {
                    this.countries = response.data.data;
                });
            },
        },
        beforeMount() {
            this.init();
        },
    };
</script>
