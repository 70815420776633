<template>
    <base-panel @overlay-clicked="close"
                class="sidebar-container"
                panel-class="sidebar"
                ref="panel">
        <div class="header">
            <h1>
                {{ $t('Add frame group') }}
            </h1>
        </div>
        <form @submit.prevent="submit"
              class="body">
            <div class="form-group">
                <label for="name">
                    {{ $t("Name") }}
                </label>
                <input id="name"
                       name="name"
                       ref="name"
                       type="text"
                       v-model="frameGroup.name">
            </div>
            <button class="primary"
                    type="submit">
                {{ $t('Store') }}
            </button>
        </form>
    </base-panel>
</template>

<script>
    import moment from 'moment';
    import BasePanel from '../components/BasePanel';

    export default {
        name: "WindowFrameGroupCreate",
        components: { BasePanel },
        data() {
            return {
                frameGroup: {},
            };
        },
        methods: {
            close() {
                this.$router.replace({
                    name: 'window-frame-groups.index',
                    query: { t: moment().format('HHmmssSSS') },
                });
            },
            submit() {
                this.$http.post('frame-groups/create', this.frameGroup)
                    .then(() => {
                        this.close();
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    };
</script>
