<template>
    <base-panel @overlay-clicked="close"
                panel-class="modal project-cancel-modal small"
                ref="panel">
        <div class="header">
            {{ ucfirst($t("racks.upper.delete_raster_lengths")) }}
        </div>
        <div class="body">
            <p>
                {{ ucfirst($t("racks.upper.delete_raster_lengths_description")) }}
            </p>
            <button @click="confirm"
                    class="primary">
                {{ ucfirst($t("global.yes")) }}
            </button>
            <router-link :to="{ name: 'upper-racks.index', params: { category: 'raster-lengths' } }"
                         class="secondary"
                         tag="button">
                {{ ucfirst($t("global.no")) }}
            </router-link>
        </div>
    </base-panel>
</template>

<script>
    import BasePanel from '../components/BasePanel';
    import moment from 'moment';

    export default {
        name: "UpperRackDeleteUpperRacks",
        components: { BasePanel },
        methods: {
            close() {
                this.$router.replace({
                    name: 'upper-racks.index',
                    params: { category: 'upper-rack-raster-lengths' }
                });
            },
            confirm() {
                this.$http
                    .post(`${this.$route.params.category}/${this.$route.params.upperRackRasterLengthId}/delete`, {})
                    .then(() => {
                        this.$router.replace({
                            name: 'upper-racks.index',
                            params: { category: 'upper-rack-raster-lengths' },
                            query: { t: moment().format('HHmmssSSS') }
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    };
</script>
