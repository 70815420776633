<template>
    <base-modal ref="modal">
        <template v-slot:header>
            {{ title }}
        </template>
        <template>
            <p class="question">
                {{ question }}
            </p>
        </template>
        <template v-slot:footer>
            <button @click="onCancelClicked"
                    class="secondary"
                    type="button">
                {{ cancelText }}
            </button>
            <button @click="onConfirmClicked"
                    class="primary"
                    type="button">
                {{ confirmText }}
            </button>
        </template>
    </base-modal>
</template>

<script>
    import BaseModal from "./BaseModal";

    export default {
        name: "ConfirmModal",
        components: { BaseModal },
        props: {
            title: {
                required: true,
                type: String,
            },
            question: {
                required: true,
                type: String,
            },
            cancelText: {
                required: true,
                type: String,
            },
            confirmText: {
                required: true,
                type: String,
            },
        },
        data() {
            return {
                resolve() {
                },
                reject() {
                },
            };
        },
        methods: {
            onCancelClicked() {
                this.$refs.modal.close();
                this.reject();
            },
            onConfirmClicked() {
                this.$refs.modal.close();
                this.resolve();
            },
            confirm() {
                this.reject();

                this.$refs.modal.open();

                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
        },
    };
</script>
