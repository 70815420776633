<template>
    <base-panel @overlay-clicked="close"
                class="sidebar-container"
                panel-class="sidebar"
                ref="panel">
        <div class="header">
            <h1>
                {{ ucfirst($t('laths.new_grooves')) }}
            </h1>
        </div>
        <form class="body"
              ref="employeeForm"
              v-on:submit.prevent="submit">
            <h2>
                {{ ucfirst($t('settings.general_data')) }}
            </h2>
            <div class="form-group">
                <label for="name">
                    {{ ucfirst($t("laths.name")) }}
                </label>
                <input id="name"
                       name="name"
                       ref="name"
                       type="text"
                       v-model="formData.name" />
                <label for="value">
                    {{ ucfirst($t("laths.value")) }}
                </label>
                <input id="value"
                       name="value"
                       ref="value"
                       type="text"
                       v-model="formData.value" />
                <label for="length">
                    {{ ucfirst($t("laths.height")) }}
                </label>
                <input id="length"
                       name="length"
                       ref="length"
                       type="text"
                       v-model="formData.length" />
                <label for="useLath">
                    {{ ucfirst($t("laths.use_lath")) }}
                </label>
                <multiselect :close-on-select="true"
                             :false-value="nee"
                             :options="options"
                             :searchable="false"
                             :true-value="ja"
                             deselect-label=""
                             id="useLath"
                             name="useLath"
                             ref="useLath"
                             select-label=""
                             selected-label=""
                             type="text"
                             v-model="formData.useLath" />
            </div>

            <button class="primary">
                {{ ucfirst($t('laths.new_grooves')) }}
            </button>
        </form>
    </base-panel>
</template>

<script>
    import moment from "moment";
    import BasePanel from "../components/BasePanel";
    import { Multiselect } from "vue-multiselect";

    export default {
        name: "LathCreateWidths",
        components: { BasePanel, Multiselect },
        data() {
            return {
                grooves: [],
                formData: {},
                options: ['1', '0']
            };
        },
        methods: {
            close() {
                this.$router.replace({
                    name: 'laths.index',
                    params: this.$route.params,
                });
            },
            submit() {
                const formData = this.formData;

                this.$http.post('grooves/create', formData)
                    .then(() => {
                        this.$router.replace({
                            name: 'laths.index',
                            params: this.$route.params,
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        beforeMount() {
            this.$http.get('grooves')
                .then((response) => {
                    this.lathsTypes = response.data.data;
                });
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    }
</script>
