<template>
    <div class="parameter-info-form">
        <div class="segment-form"
             v-for="(segment, index) in $parent.parameter.segments">
            <div class="head"
                 v-if="$parent.parameter.segments.length > 0">
                <h2 v-text="`Segment ${index + 1}`" />
                <button @click="$parent.removeSegment(index)"
                        class="remove"
                        type="button"
                        v-if="$parent.parameter.segments.length > 1">
                    <img :alt="ucf$t('objects.formula.remove')"
                         src="/images/icons/close-icon-2.svg">
                </button>
            </div>
            <div class="form-group">
                <label for="length">
                    {{ ucf$t('parameters.segments.length') }}
                </label>
                <input id="length"
                       name="length"
                       type="number"
                       v-model="segment.length">
            </div>
            <div class="form-group">
                <label for="optionType">
                    {{ ucf$t('parameters.segments.option-types.label') }}
                </label>
                <select id="optionType"
                        name="optionType"
                        v-model="segment.optionType">
                    <option :value="undefined">
                        {{ ucf$t('parameters.segments.option-types.placeholder') }}
                    </option>
                    <option :value="revitOption"
                            v-for="(revitOption) of $parent.revitOptions">
                        {{ ucf$t('parameters.segments.option-types.' + revitOption.translation) }}
                    </option>
                </select>
            </div>
        </div>
        <button @click="$parent.addSegment(1)"
                class="primary add"
                type="button">
            {{ ucf$t('parameters.segments.add') }}
        </button>
    </div>
</template>

<script>
    export default {
        name: 'ParameterSegment',
    };
</script>
