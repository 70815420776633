<template>
    <div class="form-element">
        <div class="head">
            <div class="h-tag"
                 v-html="hTag" />
            <button @click="openVisibleWhen"
                    class="visible-when"
                    type="button"
                    v-if="!root">
                {{ ucf$t('parameters.forms.change-visible-when') }}
            </button>
            <button @click="addChild"
                    class="add"
                    type="button">
                {{ ucf$t('parameters.forms.add-element') }}
            </button>
            <button @click="$emit('delete')"
                    class="remove"
                    type="button"
                    v-if="showDeleteButton">
                <img :alt="ucf$t('objects.formula.remove')"
                     src="/images/icons/close-icon-2.svg">
            </button>
        </div>
        <div class="form-group">
            <label for="label">
                {{ ucf$t("parameters.forms.label") }}
            </label>
            <input id="label"
                   name="label"
                   ref="label"
                   type="text"
                   v-model="formElement.label">
        </div>
        <div class="form-group">
            <label for="label">
                {{ ucf$t("parameters.forms.default") }}
            </label>
            <input id="default"
                   name="default"
                   ref="default"
                   type="text"
                   v-model="formElement.default">
        </div>
        <div class="form-group">
            <label for="type">
                {{ ucf$t("parameters.forms.select-type") }}
            </label>
            <select id="type"
                    name="type"
                    v-model="formElement.type">
                <option :value="type"
                        v-for="type of formElementTypes">
                    {{ ucf$t('parameters.forms.type.' + type) }}
                </option>
            </select>
        </div>
        <div class="form-group"
             v-if="formElement.type === formElementTypes.checkbox">
            <label for="updatesParameter">
                {{ ucf$t('parameters.forms.updates-parameter') }}
            </label>
            <label class="checkbox">
                <input id="updatesParameter"
                       name="updatesParameter"
                       type="checkbox"
                       v-model="formElement.updatesParameter">
                <p>
                    {{ formElement.updatesParameter ? ucf$t('parameters.forms.does-update-parameter')
                        : ucf$t('parameters.forms.does-not-update-parameter') }}
                </p>
            </label>
        </div>
        <div v-if="showSegments && formElement.type === formElementTypes.select">
            <div class="form-group">
                <label for="segment">
                    {{ ucf$t("parameters.forms.segment") }}
                </label>
                <select id="segment"
                        name="segment"
                        v-model="formElement.segment">
                    <option :value="segment"
                            v-for="(segment, index) of segments">
                        {{ ucf$t('parameters.segments.option-types.' + segment.optionType.translation) }}
                        ({{ index + 1 }})
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label for="options">
                    {{ ucf$t("parameters.forms.options") }}
                </label>
                <vue-multiselect :close-on-select="false"
                                 :options="options"
                                 :placeholder="ucf$t('parameters.forms.select-options')"
                                 class="w-1/4 mr-4"
                                 deselect-label=""
                                 id="options"
                                 label="name"
                                 multiple
                                 name="options[]"
                                 select-label=""
                                 selected-label=""
                                 show-labels
                                 track-by="id"
                                 v-model="formElement.options" />
            </div>
        </div>
        <hr>
        <div class="children">
            <div class="child"
                 v-for="(child, index) of formElement.children">
                <form-element :element-id="`${elementId}.${index + 1}`"
                              :form-element="child"
                              :parameter="parameter"
                              :segments="segments"
                              :level="index + 1"
                              @delete="deleteChild"
                              show-delete-button />
            </div>
        </div>

        <base-panel @overlay-clicked="closeVisibleWhen"
                    panel-class="modal no-overflow"
                    ref="visibleWhen"
                    v-if="!root">
            <div class="header">
                <h1>
                    {{ ucf$t('parameters.forms.change-visible-when') }}
                </h1>
                <div @click="closeVisibleWhen"
                     class="close">
                    <img alt="close"
                         src="/images/icons/close-icon.svg">
                </div>
            </div>
            <div class="body">
                <div class="form-group">
                    <label for="alwaysVisible">
                        {{ ucf$t('parameters.forms.always-visible') }}
                    </label>
                    <label class="checkbox">
                        <input id="alwaysVisible"
                               name="alwaysVisible"
                               type="checkbox"
                               v-model="formElement.alwaysVisible">
                        <p>
                            {{ formElement.alwaysVisible ? ucf$t('parameters.forms.is-always-visible')
                                : ucf$t('parameters.forms.not-always-visible') }}
                        </p>
                    </label>
                </div>
                <div class="form-group"
                     v-if="!formElement.alwaysVisible && parentElement.type === formElementTypes.select">
                    <label for="visibleWhen">
                        {{ ucf$t("parameters.forms.visible-when") }}
                    </label>
                    <vue-multiselect :close-on-select="false"
                                     :options="parentElement.options"
                                     :placeholder="ucf$t('parameters.forms.select-visible-when')"
                                     class="w-1/4 mr-4"
                                     deselect-label=""
                                     id="visibleWhen"
                                     label="name"
                                     multiple
                                     name="visibleWhen[]"
                                     select-label=""
                                     selected-label=""
                                     show-labels
                                     track-by="id"
                                     v-model="formElement.visibleWhen" />
                </div>
                <div class="form-group"
                     v-if="!formElement.alwaysVisible && parentElement.type === formElementTypes.checkbox">
                    <label for="visibleWhen">
                        {{ ucf$t("parameters.forms.visible-when") }}
                    </label>
                    <vue-multiselect :close-on-select="false"
                                     :options="checkboxValues"
                                     :placeholder="ucf$t('parameters.forms.select-visible-when')"
                                     class="w-1/4 mr-4"
                                     deselect-label=""
                                     id="visibleWhen"
                                     label="name"
                                     multiple
                                     name="visibleWhen[]"
                                     select-label=""
                                     selected-label=""
                                     show-labels
                                     track-by="value"
                                     v-model="formElement.visibleWhen" />
                </div>
                <div class="form-group"
                     v-if="!formElement.alwaysVisible && parentElement.type === formElementTypes.input">
                    <label for="visibleWhen">
                        {{ ucf$t("parameters.forms.visible-when") }}
                    </label>
                    <vue-tags-input :tags="formElement.visibleWhen"
                                    @tags="newValue => formElement.visibleWhen = newValue"
                                    v-model="tagInput" />
                </div>
            </div>
        </base-panel>
    </div>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import VueMultiselect from 'vue-multiselect';
    import { deepClone } from '../configuration/methods';
    import FormElement, { FORM_ELEMENT_TYPES } from '../models/FormElement';
    import BasePanel from './BasePanel';

    export default {
        name: 'FormElement',
        components: { BasePanel, VueMultiselect, VueTagsInput },
        props: {
            root: {
                type: Boolean,
                required: false,
                default() {
                    return false;
                },
            },
            showDeleteButton: {
                type: Boolean,
                required: false,
                default() {
                    return false;
                },
            },
            formElement: {
                type: Object,
                required: true,
            },
            parameter: {
                type: Object,
                required: true,
            },
            segments: {
                type: Array,
                required: true,
            },
            level: {
                required: false,
                type: Number,
                default() {
                    return 1;
                },
            },
            elementId: {
                required: false,
                type: String,
                default() {
                    return '0';
                },
            },
        },
        data() {
            return {
                tagInput: '',
                formElementTypes: FORM_ELEMENT_TYPES,
                checkboxValues: [
                    {
                        name: this.ucf$t('parameters.forms.checkbox-on'),
                        value: true,
                    },
                    {
                        name: this.ucf$t('parameters.forms.checkbox-off'),
                        value: false,
                    },
                ],
            };
        },
        methods: {
            openVisibleWhen() {
                this.$refs.visibleWhen.open();
            },
            closeVisibleWhen() {
                this.$refs.visibleWhen.close();
            },
            addChild() {
                this.formElement.children.push(new FormElement());
            },
            deleteChild(index) {
                this.formElement.children.splice(index, 1);
            },
        },
        watch: {
            'formElement.type': {
                handler(nType, oType) {
                    if (this.empty(nType)) {
                        this.formElement.options = [];
                        return;
                    }

                    if (!this.empty(oType) && nType === oType) {
                        return;
                    }

                    switch (nType) {
                    case FORM_ELEMENT_TYPES.input:
                        this.formElement.options = [];
                        this.formElement.segment = undefined;
                        break;
                    case FORM_ELEMENT_TYPES.select:
                        this.formElement.options = [];

                        if (!this.empty(this.parameter) && !this.parameter.parameterIsSegmented) {
                            this.formElement.options = deepClone(this.parameter.segments[0].optionType.data);
                        }
                        break;
                    case FORM_ELEMENT_TYPES.checkbox:
                        this.formElement.segment = undefined;
                        this.formElement.options = [true, false];
                        break;
                    default:
                        throw new Error('Type unsupported...');
                    }
                },
                immediate: true,
            },
            'formElement.segment': {
                handler(nSegment, oSegment) {
                    if (this.empty(nSegment)) {
                        this.formElement.options = [];
                        return;
                    }

                    if (!this.empty(oSegment) && nSegment.optionType.class === oSegment.optionType.class) {
                        return;
                    }

                    this.formElement.options = deepClone(nSegment.optionType.data);
                },
                immediate: true,
            },
        },
        computed: {
            showSegments: {
                get() {
                    return this.segments.length > 0;
                },
            },
            options: {
                get() {
                    const { empty, parameter, showSegments } = this;
                    const { segment } = this.formElement;

                    if (showSegments) {
                        if (empty(segment)) {
                            return [];
                        }

                        return segment.optionType.data;
                    }

                    return parameter.optionType.data;
                },
            },
            elementTypes: {
                get() {
                    return FORM_ELEMENT_TYPES;
                },
            },
            hId: {
                get() {
                    const hId = (this.$parent.hId || '').replace(/\s/g, '');
                    const { level } = this;

                    if (hId.length === 0) {
                        return `${level}`;
                    }

                    return `${hId}.${level}`;
                },
            },
            hTag: {
                get() {
                    return `<h3>${this.ucf$t('parameters.form-element')} ${this.hId}</h3>`;
                },
            },
            parentElement: {
                get() {
                    return this.$parent.formElement || undefined;
                },
            },
        },
    };
</script>
