<template>
    <div class="frame-parameters">
        <div class="form-group">
            <label for="options">
                {{ $t("Choose parameters") }}
            </label>
            <vue-multiselect :close-on-select="false"
                             :options="options"
                             :placeholder="$t('Select parameters')"
                             class="w-1/4 mr-4"
                             deselect-label=""
                             id="options"
                             label="name"
                             multiple
                             name="options[]"
                             select-label=""
                             selected-label=""
                             show-labels
                             track-by="id"
                             v-model="$parent.frame.parameters" />
        </div>
        <div class="form-group"
             v-show="$parent.frame.parameters.length > 0">
            <label>
                {{ $t("Chosen parameters") }}
            </label>
            <vue-draggable :disabled="false"
                           :list="$parent.frame.parameters"
                           @end="dragging = false"
                           @start="dragging = true"
                           animation="200"
                           class="parameters"
                           ghost-class="ghost"
                           group="description">
                <transition-group :name="!dragging ? 'flip-list' : null"
                                  type="transition">
                    <div :key="parameter.id"
                         class="list-group-item"
                         v-for="parameter in $parent.frame.parameters">
                        {{ parameter.name }}
                    </div>
                </transition-group>
            </vue-draggable>
        </div>
    </div>
</template>

<script>
    import VueMultiselect from 'vue-multiselect';
    import VueDraggable from 'vuedraggable';

    export default {
        name: 'WindowFrameParameters',
        components: { VueDraggable, VueMultiselect },
        data() {
            return {
                dragging: false,
            };
        },
        computed: {
            options: {
                get() {
                    return this.$parent.parameters;
                },
            },
        },
    };
</script>
