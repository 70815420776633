import snakeCase from 'lodash/snakeCase';
import pluralize from 'pluralize';
import BaseOverview from "../../components/BaseOverview";

function Overview(service, vueConfig = {}) {
    const entity = service.getEntity();
    const entities = pluralize(service.getEntity());

    return {
        ...vueConfig,
        data() {
            return {
                items: [],
                search: '',
                page: 1,
                maxPage: 2,
                orderBy: {
                    field: `${snakeCase(entities)}.id`,
                    sequence: 'DESC',
                },
            };
        },
        methods: {
            setOrderBy(field) {
                const sequence = (field === this.orderBy.field && this.orderBy.sequence === 'DESC')
                    ? 'ASC'
                    : 'DESC';

                this.orderBy = {
                    field,
                    sequence,
                };

                this.reset();
            },
            loadItems($state) {
                this.$http
                    .get(this.slug, {
                        page: this.page,
                        orderBy: this.orderBy.field,
                        sequence: this.orderBy.sequence,
                        search: this.search,
                    })
                    .then(({ data }) => {
                        if (data.data.length === 0) {
                            $state.complete();
                            return;
                        }

                        data.data.forEach((customer) => {
                            this.items.push(customer);
                        });

                        this.maxPage = data.meta.last_page;
                        $state.loaded();
                    });
                this.page++;
            },
        },
        watch: {
            search: {
                async handler(search) {
                    await this.$router.replace({
                        ...this.$route,
                        query: {
                            ...this.$route.query,
                            search,
                        },
                    });
                },
            },
        },
    };
}

export default function IndexView(service, vueConfig = {}) {
    return {
        ...vueConfig,
        components: { ...(vueConfig.components || {}), 'base-overview': Overview(service, BaseOverview) },
    };
}
