<template>
    <div class="horizontal-select">
        <div :class="{ active: !empty(value) && option[trackBy] === value[trackBy] }"
             @click="$emit('input', option)"
             class="option"
             v-for="option of options">
            <slot :option="option">
                {{ option[showBy] }}
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HorizontalSelect",
        props: {
            trackBy: {
                required: true,
                type: String,
            },
            showBy: {
                required: true,
                type: String,
            },
            options: {
                required: true,
                type: Array,
            },
            value: {
                type: Object,
            },
        },
    };
</script>

<style lang="scss">
    @import "../../sass/variables";

    .horizontal-select {
        display   : flex;
        flex-flow : row nowrap;
        width     : calc(100% - 64px);
        margin    : 0 32px 32px 32px;

        .option {
            display          : flex;
            flex-flow        : column nowrap;
            justify-content  : center;
            flex             : 1 1 0;
            height           : 86px;
            font-weight      : bold;
            text-align       : center;
            box-sizing       : border-box;
            border-top       : 1px solid rgba(0, 0, 0, 0.1);
            border-bottom    : 1px solid rgba(0, 0, 0, 0.1);
            border-right     : 1px solid rgba(0, 0, 0, 0.1);
            cursor           : pointer;
            transition       : background-color .3s ease-out;
            background-color : white;

            &:hover {
                background-color : $primary;
            }

            &:first-child {
                border-radius : 4px 0 0 4px;
                border-left   : 1px solid rgba(0, 0, 0, 0.1);
            }

            &:last-child {
                border-radius : 0 4px 4px 0;
                border-right  : 1px solid rgba(0, 0, 0, 0.1);
            }

            &.active {
                background-color : $pink;
                color            : #ffffff;

                span {
                    opacity : 1;
                }
            }

            strong {
                font-weight : 600;
                font-size   : 18px;
                line-height : 18px;
            }

            span {
                font-weight : 600;
                font-size   : 14px;
                line-height : 14px;
                opacity     : 0.4;
                margin-top  : 6px;
            }
        }
    }
</style>
