<template>
    <transition name="fade">
        <div :key="$route.params.category"
             class="glass-container">
            <div class="header">
                <h1>{{ ucfirst($t("frames.frames")) }}</h1>
            </div>
            <div class="tabs">
                <router-link :to="{ name: $route.name, params: { category: 'brands' } }"
                             class="tab-item">
                    {{ ucfirst($t("frames.brands")) }}
                </router-link>
                <router-link :to="{ name: $route.name, params: { category: 'colors' } }"
                             class="tab-item">
                    {{ ucfirst($t("frames.colors")) }}
                </router-link>
                <router-link :to="{ name: $route.name, params: { category: 'frame-woods' } }"
                             class="tab-item">
                    {{ ucfirst($t("frames.woods")) }}
                </router-link>
                <router-link :to="{ name: $route.name, params: { category: 'frame-weights' } }"
                             class="tab-item">
                    {{ ucfirst($t("frames.weights")) }}
                </router-link>
            </div>
            <div class="search-container">
                <div class="searchbar">
                    <img src="/images/icons/search-icon.svg"
                         title="search-icon">
                    <input :placeholder="ucfirst($t('global.search'))"
                           :value="search"
                           name="search"
                           v-on:input="setSearch" />
                </div>

                <router-link :to="{ name: 'frames.create.', query: $route.query, params: { category: 'brands' }}"
                             class="btn primary"
                             v-if="this.$route.params.category === 'brands'">
                    {{ ucfirst($t("frames.new_brand")) }}
                </router-link>

                <router-link :to="{ name: 'frames.create', query: $route.query, params: { category: 'colors' }}"
                             class="btn primary"
                             v-if="this.$route.params.category === 'colors'">
                    {{ ucfirst($t("frames.new_color")) }}
                </router-link>

                <router-link
                        :to="{ name: 'frames.create.woods', query: $route.query, params: { category: 'frame-woods' }}"
                        class="btn primary"
                        v-if="this.$route.params.category === 'frame-woods'">
                    {{ ucfirst($t("frames.new_wood")) }}
                </router-link>

                <router-link
                        :to="{ name: 'frames.create.weights', query: $route.query, params: { category: 'frame-weights' }}"
                        class="btn primary"
                        v-if="this.$route.params.category === 'frame-weights'">
                    {{ ucfirst($t("frames.new_weights")) }}
                </router-link>
            </div>

            <div class="table">
                <div class="row header">
                    <div class="column pointer"
                         v-on:click="setOrderBy('name')">
                        {{ ucfirst($t("frames.name")) }}
                    </div>
                </div>
                <transition-group name="list" v-if="this.$route.params.category === 'frame-woods'">
                    <router-link :key="frame_weight.id"
                                 :to="{ name: 'frames.edit.woods', params: { category: 'frame-woods', frameWoodId: frame_weight.id } }"
                                 class="row"
                                 v-for="frame_weight in frame_weights">
                        <div class="column">
                            <span>
                                {{ frame_weight.name }}
                            </span>
                        </div>
                    </router-link>
                </transition-group>
                <transition-group name="list" v-if="this.$route.params.category === 'frame-weights'">
                    <router-link :key="frame_weight.id"
                                 :to="{ name: 'frames.edit.weights', params: { category: 'frame-weights', frameWeightId: frame_weight.id } }"
                                 class="row"
                                 v-for="frame_weight in frame_weights">
                        <div class="column">
                            <span>
                                {{ frame_weight.name }}
                            </span>
                        </div>
                    </router-link>
                </transition-group>
                <infinite-loading :distance="400"
                                  @infinite="infiniteHandler"
                                  ref="infiniteLoading"
                                  spinner="spiral">
                    <div class="no-results"
                         slot="no-more">{{ ucfirst($t("frames.no_more")) }}
                    </div>
                </infinite-loading>
                <transition mode="out-in"
                            name="fade">
                    <router-view :key="$route.fullPath" />
                </transition>
            </div>
        </div>
    </transition>
</template>

<script>
    import debounce from 'lodash/debounce';

    export default {
        name: 'FramesIndex',
        data() {
            return {
                frame_weights: [],
                search: this.$route.query.search || null,
                page: 1,
                maxPage: 1,
                orderBy: {
                    field: 'name',
                    sequence: 'DESC',
                },
            };
        },
        methods: {
            infiniteHandler($state) {
                let url = '/' + this.$route.params.category
                    + '?page=' + this.page
                    + '&order_field=' + this.orderBy.field
                    + '&order_sequence=' + this.orderBy.sequence;

                if (!!this.$route.query.search) {
                    url = url + '&search=' + this.$route.query.search;
                }

                if (this.page <= this.maxPage) {
                    this.$http.get(url)
                        .then((response) => {
                            let data = response.data;
                            this.frame_weights = [...this.frame_weights, ...data.data];
                            $state.loaded();
                        })
                        .catch(function (error) {
                        });

                    this.page = this.page + 1;
                } else {
                    $state.complete();
                }
            },
            setOrderBy(field) {
                if (field === this.orderBy.field) {
                    this.orderBy.sequence = this.orderBy.sequence === 'DESC' ? 'ASC' : 'DESC';
                } else {
                    this.orderBy.field = field;
                    this.orderBy.sequence = 'DESC';
                }

                this.$router.replace({
                    ...this.$route,
                    query: {
                        category: this.$route.query.category,
                        search: this.$route.query.search,
                        orderField: this.orderBy.field,
                        orderSequence: this.orderBy.sequence,
                    },
                });
            },
            setSearch: debounce(function (e) {
                this.search = e.target.value;

                this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        search: this.search,
                        orderField: this.$route.query.orderField,
                        orderSequence: this.$route.query.orderSequence,
                    },
                });
            }, 500),
        },
        beforeRouteUpdate(to, from, next) {
            this.frame_weights = [];
            this.page = 1;
            this.maxPage = 1;
            this.search = null;
            this.orderBy = {
                field: 'created_at',
                sequence: 'DESC',
            };

            if (to.params.category === from.params.category) {
                this.search = to.query.search;
                this.orderBy = {
                    field: to.query.orderField,
                    sequence: to.query.orderSequence,
                };
            }

            setTimeout(() => {
                this.$refs.infiniteLoading.stateChanger.reset();
            }, 500);

            return next();
        },
        mounted() {
            this.search = this.$route.query.search || null;
        },
    };
</script>


<style scoped>

</style>